import axios from '../js/oss/axiosCore';

class Actions {
    static loadingData = (type, widgetId) => ({
        type: `LOAD_${type}_REQUEST`,
        payload: {
            widgetId
        }
    });

    static setData = (type, data, widgetId) => ({
        type: `LOAD_${type}_SUCCESS`,
        payload: {
            data,
            widgetId
        }
    });

    static throwError = (type, errorMsg, widgetId) => ({
        type: `LOAD_${type}_FAILURE`,
        payload: {
            error: errorMsg,
            widgetId
        }
    });

    static asyncActionsFactory = (type, url, widgetId) => async (dispatch, getState) => {
        dispatch(Actions.loadingData(type, widgetId));
        try {
            const {data} = await axios({
                method: 'GET',
                url,
                responseType: 'json'
            }, null, getState());
            dispatch(Actions.setData(type, data, widgetId));
        } catch (e) {
            dispatch(Actions.throwError(type, e, widgetId));
            throw e;
        }
    };
}

export default Actions;
