import {combineReducers} from 'redux';
import reduceReducers from 'reduce-reducers';
import filterReducers from './Widgets/filterReducers';
import selectedObjects from './Widgets/selectedObjectsReducer';
import globalOptionsReducers from './globalOptionsReducers';
import inputReducers from './Components/inputReducers';
import tabelReducer from './Preferences/tableReducers/tableReducer';
import contextButtonsReducer from './Widgets/contextButtonsReducer';
import decorationReducer from './decorationReducer';
import ReduxFactory from '../Redux/ReduxFactory';
import widgetParameters from './WidgetParameters/registerReducer';

import KPIViewReducer from './KPIViewReducer';

import viewReducer from './viewReducer';
import dashboardReducer from './dashboardReducer';

import languageReducer from './languageReducer';

//reducer for data operations as get or set
import dataOperations from './Data/dataOperationsReducer';

import tabsReducers from './Preferences/tabsReducer/tabsReducer';
import affectedObjects from './Data/affectedObjects';
import subscribeEvents from './Data/subscribeEvents';

import customDataReducers from './Preferences/CustomWidgets/customDataReducers';
import viewsManagerReducer from './Navigation/viewsManagerReducer';
import modelReducer from './modelReducer';
import measurementProfileReducer from './measurementProfileReducer';

const appReducers = combineReducers({
    affectedObjects,
    components: inputReducers,
    contextButtons: contextButtonsReducer,
    dashboard: dashboardReducer,
    data: dataOperations,
    dataTest: ReduxFactory.REDUCERS.asyncReducersFactory('DATA_TEST'),
    decoration: decorationReducer,
    globalOptions: globalOptionsReducers,
    KPIViewReducer,
    languageReducer,
    measurementProfile: measurementProfileReducer,
    model: modelReducer,
    navigation: reduceReducers(ReduxFactory.REDUCERS.asyncReducersFactory('NAVIGATION'), viewsManagerReducer),
    preferences: reduceReducers(tabelReducer, tabsReducers, customDataReducers),
    subscribeEvents,
    viewReducer,
    widgets: reduceReducers(filterReducers, selectedObjects),
    widgetParameters
});

export default appReducers;
