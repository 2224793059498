import appReducers from './index';
import {CLEAR_STORE, CLEAR_WIDGET_PROPERTIES_FROM_MODAL} from '../actions/actionsTypes';

const getInitialState = (viewReducerState) => {
    return {
        components: {},
        data: {},
        dataTest: {},
        decoration: {},
        preferences: {
            tables: {},
            tabs: {},
            customWidgets: {}
        },
        widgets: {},
        KPIViewReducer: {},
        affectedObjects: {},
        subscribeEvents: {},
        viewReducer: {
            ...viewReducerState,
            isBookmarkPopupOpened: false,
            isViewAsBookmark: false
        },
        widgetPreferences: {
            definitions: {
                data: {},
                filter: {}
            },
            values: {
                data: {},
                filter: {}
            }
        }
    };
};

const rootReducer = (state, action) => {
    let newState = {...state};
    if (action.type === CLEAR_STORE) {
        newState = {...state, ...getInitialState(state?.viewReducer || {})};
    } else if (action.type === CLEAR_WIDGET_PROPERTIES_FROM_MODAL) {
        newState = {...action.payload};
    }

    return appReducers(newState, action);
};

export default rootReducer;
