import {
    SET_VIEW_AS_BOOKMARK,
    UNSET_VIEW_AS_BOOKMARK,
    START_LOADING_BOOKMARK_CONFIG,
    STOP_LOADING_BOOKMARK_CONFIG,
    SET_VIEW_ID,
    OPEN_BOOKMARK_POPUP,
    CLOSE_BOOKMARK_POPUP,
    SET_VIEW_CONFIG,
    SET_ACTIVE_VIEW,
    UPDATE_VIEW_CONFIG,
    OPEN_MODALS,
    CLOSE_MODALS,
    OPEN_PROMPTS,
    CLOSE_PROMPTS,
    REMOVE_VIEW_CONFIG,
    SET_VIDEOWALL_MODE,
    UNSET_VIDEOWALL_MODE
} from '../actions/actionsTypes';
import {uniqueId} from '../js/oss/utils';

const defaultChunk = {
    views: {},
    layout: {
        activeView: '',
        modals: [],
        prompts: []
    },
    isVideowallMode: false
};

const updateActiveView = (state, payload) => {
    const {viewId} = payload;
    const {layout} = state;
    return {
        ...state,
        layout: {
            ...(layout || {}),
            activeView: viewId
        }
    };
};

const setViewConfig = (state, payload) => {
    const {viewId, config} = payload;
    const {views} = state;
    return {
        ...state,
        views: {
            ...views,
            [viewId]: config
        }
    };
};

const removeViewConfig = (state, payload) => {
    const {viewId} = payload;
    const {views, layout} = state;
    if (!views[viewId]) {
        return state;
    }
    const newViews = {...views};
    delete newViews[viewId];
    return {
        ...(layout.activeView === viewId ? updateActiveView(state, {viewId: ''}) : state),
        views: newViews
    };
};

const updateViewConfig = (state, payload) => {
    const {viewId, fields} = payload;
    if (!viewId) {
        return state;
    }
    const {views} = state;
    const {[viewId]: viewConfig} = views || {};
    return {
        ...state,
        views: {
            ...views,
            [viewId]: {
                ...(viewConfig || {}),
                ...(fields || {})
            }
        }
    };
};

const updateViews = (state, toAdd, toRemovePayload, chunkName = 'modals') => {
    const {layout, views} = state;
    let newLayouts = [...(layout?.[chunkName] || [])];
    const newViews = {...views};
    const {[chunkName]: toRemoveByIds, paths: toRemoveByUrls} = toRemovePayload;
    if (Array.isArray(toAdd)) {
        toAdd.forEach(({url, additionalRequestConfig}) => {
            const guid = uniqueId();
            newLayouts.push({
                url,
                guid,
                additionalRequestConfig
            });
        });
    }
    if (Array.isArray(toRemoveByIds)) {
        newLayouts = newLayouts.filter(({guid}) => !toRemoveByIds.includes(guid));
    }
    if (Array.isArray(toRemoveByUrls)) {
        newLayouts = newLayouts.filter(({url}) => !toRemoveByUrls.includes(url));
    }
    return {
        ...state,
        layout: {
            ...(layout || {}),
            [chunkName]: newLayouts
        },
        views: newViews
    };
};

//eslint-disable-next-line complexity
export default (state = defaultChunk, {type, payload}) => {
    switch (type) {
        case SET_VIEW_AS_BOOKMARK: {
            return {
                ...state,
                isViewAsBookmark: true
            };
        }
        case UNSET_VIEW_AS_BOOKMARK: {
            return {
                ...state,
                isViewAsBookmark: false
            };
        }
        case START_LOADING_BOOKMARK_CONFIG: {
            return {
                ...state,
                isBookmarkConfigLoaded: false
            };
        }
        case STOP_LOADING_BOOKMARK_CONFIG: {
            return {
                ...state,
                isBookmarkConfigLoaded: true
            };
        }
        case SET_VIEW_ID: {
            return {
                ...state,
                viewId: payload.viewId
            };
        }
        case OPEN_BOOKMARK_POPUP: {
            return {
                ...state,
                isBookmarkPopupOpened: true
            };
        }
        case CLOSE_BOOKMARK_POPUP: {
            return {
                ...state,
                isBookmarkPopupOpened: false
            };
        }
        case SET_VIEW_CONFIG: {
            return setViewConfig(state, payload);
        }
        case SET_ACTIVE_VIEW: {
            return updateActiveView(state, payload);
        }
        case UPDATE_VIEW_CONFIG: {
            return updateViewConfig(state, payload);
        }
        case REMOVE_VIEW_CONFIG:
            return removeViewConfig(state, payload);
        case OPEN_MODALS:
            return updateViews(state, payload?.modals, [], 'modals');
        case CLOSE_MODALS:
            return updateViews(state, [], payload, 'modals');
        case OPEN_PROMPTS:
            return updateViews(state, payload?.prompts, [], 'prompts');
        case CLOSE_PROMPTS:
            return updateViews(state, [], payload, 'prompts');
        case SET_VIDEOWALL_MODE: {
            const {videowallMode} = payload;
            return {
                ...state,
                isVideowallMode: videowallMode
            };
        }
        default:
            return state;
    }
};
