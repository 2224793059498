import {setInContext} from '@web-core/store';
import {addListOfQueryParams, deleteQueryParams} from '../../js/oss/queryParams';
import {setQCQueryParams} from '../../js/oss/queryContextParams';

let iNContext = 'primaryContext=current;lifecycleState=active';
setInContext(iNContext);

let queryContextParams = {};
let viewQueryParams = {};
const subscribers = new Set();
let queryContextInstance = null;

const QueryContextStore = {
    getINContext() {
        return iNContext;
    },
    setINContext(_iNContext) {
        iNContext = _iNContext;
        setInContext(iNContext);
    },
    getQueryContextParams() {
        return queryContextParams;
    },
    getViewQueryParams() {
        return viewQueryParams;
    },
    setViewQueryParams(_viewQueryParams) {
        viewQueryParams = _viewQueryParams;
    },
    setQueryContextParams(_queryContextParams, _viewQueryParams, makeRedirect = true) {
        const url = deleteQueryParams(window.location.hash);
        queryContextParams = Object.assign({}, _queryContextParams);
        viewQueryParams = Object.assign({}, _viewQueryParams);
        const allQueryParams = Object.assign({}, _queryContextParams, _viewQueryParams);
        setQCQueryParams(_queryContextParams);
        if (makeRedirect) {
            window.location.hash = addListOfQueryParams(url, allQueryParams);
        }
        this.emitChange();
    },
    deleteQueryContextParams() {
        window.location.hash = deleteQueryParams(window.location.hash, queryContextParams);
        queryContextParams = {};
        setQCQueryParams({});
        this.emitChange();
    },
    changeQueryContext(params) {
        if (queryContextInstance) {
            queryContextInstance.getQueryContext(params, 0, false);
        }
    },
    setQueryContextInstance(instance) {
        queryContextInstance = instance;
    },
    removeQueryContextInstance(subscriber) {
        queryContextInstance = null;
    },
    subscribe(subscriber) {
        subscribers.add(subscriber);
    },
    unsubscribe(subscriber) {
        subscribers.delete(subscriber);
    },
    emitChange() {
        subscribers.forEach((element) => {
            try {
                element.onQCStoreChanged();
            } catch (e) {
                console.warn(
                    `${element},  subscribed one QC store change, but didn\'t implement onQCStoreChanged method`
                );
            }
        });
    }
};

export default QueryContextStore;
