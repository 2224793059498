import _ from 'underscore';
import {setOldTranslateHandler} from '@web-core/store';
import Logger from '../../Logger/Logger';

let languageStore = null;
const LANG_PREFIX = '#~';
const LANG_SUFFIX = '~#';

export const regex = new RegExp(
    '(' + LANG_PREFIX + ')(.+?)_([\\W^(~)$&*()!+\\|\\s|\\[|\\].-|%]+?)(' + LANG_SUFFIX + ')',
    'gi'
);

export const setLanguageStore = (store) => {
    if (!languageStore) {
        languageStore = store;
    }
};

export const isLabelAvailable = (newLabel) => {
    if (_.isUndefined(newLabel.values[languageStore.getCurrentLocale()])) {
        return false;
    }
    return newLabel.values[languageStore.getCurrentLocale()] !== '';
};

export const translateValue = ([match, , dictionary, label], text) => {
    // find in all dictionaries new label value. when no results -> return label
    const {allDictionaries} = languageStore;

    if (
        allDictionaries[dictionary] &&
        allDictionaries[dictionary].labels &&
        allDictionaries[dictionary].labels[label]
    ) {
        const newLabel = allDictionaries[dictionary].labels[label];
        if (newLabel && Object.prototype.hasOwnProperty.call(newLabel, 'values')) {
            if (!isLabelAvailable(newLabel)) {
                return text.replace(match, newLabel.values.default);
            }
            return text.replace(match, newLabel.values[languageStore.getCurrentLocale()]);
        }
    }
    return text.replace(match, label);
};

export const searchTreeForTranslation = (o) => {
    if (typeof o === 'object' && o !== null) {
        Object.keys(o).forEach((i) => {
            if (typeof o[i] === 'object') {
                searchTreeForTranslation(o[i]);
            } else {
                let found;
                const temp = o[i];
                // check if value need to be translated
                while ((found = regex.exec(temp)) !== null) {
                    // eslint-disable-next-line no-param-reassign
                    o[i] = translateValue(found, o[i]);
                }
            }
        });
    }
    return o;
};

export const lookForNewDictionaries = (elements, data) => {
    const list = {};
    if (!elements.length) {
        return Promise.resolve(data);
    }

    // iterate through data array and get all dictionaries names
    _.each(elements, (el) => {
        let found;
        while ((found = regex.exec(el)) !== null) {
            list[found[2]] = true;
        }
    });

    const pendingDictionaries = _.map(list, (value, key) => {
        return languageStore.getNewDictionary(key);
    });

    return Promise.allSettled(pendingDictionaries)
        .then(() => {
            // then look through all data array and find values to translate
            searchTreeForTranslation(data);
            return data;
        })
        .catch((error) => {
            Logger.of('withTranslation').error(error);
        });
};

export const translateObject = (data) => {
    // select all data elements which contains lang_prefix
    const dataToString = JSON.stringify(data);
    const elems = (dataToString && dataToString.match(regex)) || [];
    return lookForNewDictionaries(elems, data);
};

setOldTranslateHandler(translateObject);
