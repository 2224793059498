import axios from 'axios';
import _ from 'underscore';
import {getCurrentLocale, getMeasurementProfile} from '@web-core/store';
// import {Tracer, ExplicitContext, BatchRecorder} from 'zipkin';
// import wrapAxios from 'zipkin-axios';
// import {HttpLogger} from 'zipkin-transport-http';
import {keycloakInstance as keycloak} from '../keycloakInstance';
import QueryContextStore from '../../OssApp/QueryContext/QueryContextStore';
import * as langUtils from './languageUtils';
import systemMessage from './SystemMessages';
import * as utils from './utils';
import {addListOfQueryParams} from './queryParams';
import {MODE} from '../../actions/globalOptionsActions';
import errorDictionary from '../errorDict.json';
import OssDateTime from '../helpers/ossDateTime';

/**
 * @module axiosCore
 * @category framework
 */

const {ALPHA} = MODE;
const {CancelToken} = axios;
const axiosRequestsCache = {};
const AJAX_TYPE = '_AJAX_';
const MAX_URL_LENGTH = 2000;
const ERROR_403 = 403;

// Setup zipkin components
// const loggerEndpoint = 'api/v1/spans';
// const ctxImpl = new ExplicitContext();
// const recorder = new BatchRecorder({
//     logger: new HttpLogger({
//         endpoint: loggerEndpoint
//     })
// });
// const tracer = new Tracer({ctxImpl, recorder});
// const serviceName = 'web-framework';

// eslint-disable-next-line complexity
function getBasicAxiosConfig(additionalHeaders, widgetInstance, source, method) {
    const axiosConfig = {
        headers: {}
    };

    axiosConfig.cancelToken = source.token;
    axiosConfig.method = method || 'GET';
    axiosConfig.headers['X-Requested-With'] = 'XMLHttpRequest';
    axiosConfig.headers.INContext = QueryContextStore.getINContext();
    if (keycloak) {
        axiosConfig.headers.Authorization = 'Bearer ' + keycloak.token;
    }
    axiosConfig.headers['Accept-Language'] = getCurrentLocale();
    axiosConfig.headers['Time-Zone'] = OssDateTime.getCurrentZone();
    axiosConfig.headers['Date-Format'] = OssDateTime.getDateTimeFormat();
    axiosConfig.headers['Measurement-Profile-Name'] = getMeasurementProfile();
    _.extend(axiosConfig.headers, additionalHeaders);

    return axiosConfig;
}

function parseGivenData(data) {
    // Do not stringify data when string, number, boolean or FormData
    if (
        !(typeof data === 'string' || typeof data === 'number' || typeof data === 'boolean' || data instanceof FormData)
    ) {
        // Change data to send from object to string
        return JSON.stringify(data);
    }
    return data;
}

export function getRequestId(widgetInstance, requestIdParams) {
    if (widgetInstance && requestIdParams) {
        let requestId;
        if (widgetInstance.getEId) {
            requestId = widgetInstance.getEId();
        } else if (widgetInstance.props && widgetInstance.props.id) {
            requestId = widgetInstance.props.id;
        }
        if (requestId && requestIdParams.functionName && requestIdParams.componentName) {
            requestId += '-' + requestIdParams.functionName + '-' + requestIdParams.componentName;
        }
        return requestId;
    }
    return null;
}

export function abortIfDuplicate(requestId) {
    if (requestId && axiosRequestsCache[requestId]) {
        axiosRequestsCache[requestId].source.cancel();
        delete axiosRequestsCache[requestId];
        console.info(`REQUEST ${requestId} HAS BEEN ABORTED`);
    }
}

function cacheRequest(requestId, source) {
    if (requestId) {
        axiosRequestsCache[requestId] = {
            source
        };
    }
}

function showLoadingIndicator(widgetInstance, shouldShowLoader) {
    if (widgetInstance && shouldShowLoader && typeof widgetInstance.increaseNoOfLoadingAjax === 'function') {
        widgetInstance.increaseNoOfLoadingAjax();
    }
}

function hideLoadingIndicator(widgetInstance, shouldShowLoader) {
    if (widgetInstance && shouldShowLoader && typeof widgetInstance.decreaseNoOfLoadingAjax === 'function') {
        widgetInstance.decreaseNoOfLoadingAjax();
    }
}

function getExtendedAxiosConfig(givenData, widgetInstance, requestIdParams, requestId, rest) {
    return {
        data: givenData ? parseGivenData(givenData) : undefined,
        requestId: requestId || getRequestId(widgetInstance, requestIdParams),
        ...rest
    };
}

const prepareUrlForGlobalOptions = (url, globalOptions) => {
    const queryParams = {};
    if (globalOptions?.mode === ALPHA) {
        queryParams.oss_console_mode = 'alpha';
    }
    if (globalOptions?.isLoggingExtended === true) {
        queryParams.extendLogging = true;
    }
    return addListOfQueryParams(url, queryParams);
};

const getConfigForGlobalOptions = (config, store) => {
    const {url} = config || {};
    const {globalOptions} = store || {};

    if (!url || !globalOptions) {
        return config;
    }
    const newUrl = prepareUrlForGlobalOptions(url, globalOptions);
    return {
        ...config,
        url: newUrl
    };
};

function getDefaultError(status) {
    return errorDictionary[AJAX_TYPE][status] ? errorDictionary[AJAX_TYPE][status] : errorDictionary[AJAX_TYPE].DEFAULT;
}

function afterTranslation(message, status, widgetInstance, traceId) {
    let errorMessage = message;
    if (!message) {
        errorMessage = getDefaultError(status);
    }
    widgetInstance?.message?.error?.(errorMessage, null, true, true, traceId);
}

function chooseMessageText(errorBody) {
    if (errorBody && errorBody.translation) {
        return errorBody.translation;
    }
    if (errorBody && errorBody.message && errorBody.message !== 'null') {
        return errorBody.message;
    }
    if (typeof errorBody === 'string') {
        try {
            const parsedBody = JSON.parse(errorBody);
            if (parsedBody && parsedBody.message && parsedBody.message !== 'null') {
                return parsedBody.message;
            }
        } catch (e) {
            return errorBody;
        }
    }
    return null;
}

const prepareMessage = (respJson = '') => {
    const errorBody = (respJson && respJson.error) || respJson;
    return chooseMessageText(errorBody);
};

const showSystemMessage = (e, message, traceId) => {
    systemMessage.showMessage({
        type: systemMessage.MESSAGE_TYPE.DANGER,
        text: message || e.toString(),
        traceId
    });
};

const isErrorStatus = (status) => {
    return status == 0 || (status >= 400 && status != 1000);
};

export const TRACE_ID_HEADER = 'trace-id';

export const getTraceId = (response) => {
    if (response?.headers) {
        return response?.headers?.[TRACE_ID_HEADER];
    }
    if (response?.getResponseHeader) {
        return response.getResponseHeader(TRACE_ID_HEADER);
    }
    return null;
};

export const showException = (e, widgetInstance, shouldShowSystemMessage) => {
    const {response} = e;
    const {status, data} = response || {};
    if (!isErrorStatus(status)) {
        return;
    }

    const traceId = getTraceId(response);
    const message = prepareMessage(data);
    if (!widgetInstance?.message && status !== ERROR_403) {
        return shouldShowSystemMessage ? showSystemMessage(e, message, traceId) : null;
    }
    langUtils
        .translateObject({message})
        .then(({message}) => afterTranslation(message, status, widgetInstance, traceId))
        .catch(({message}) => afterTranslation(message, status, widgetInstance, traceId));
};

export function isTokenTimedOut() {
    return !!(keycloak && keycloak.isTokenExpired());
}

/* eslint-disable jsdoc/require-param-description, jsdoc/require-param-type, jsdoc/require-returns-description */
/**
 * Translate response if axios returns json not files to download - e.g. downloading action returns system message
 *
 * @param data
 * @param headers
 * @returns {Promise<any|Blob>}
 */
async function translateBlob(data, headers = {}) {
    const hasContentDisposition = typeof headers['content-disposition'] !== 'undefined';
    if (!hasContentDisposition && data instanceof Blob && data.type === 'application/json') {
        const json = await data.text();
        return JSON.parse(json);
    }
    return data;
}

/* eslint-enable jsdoc/require-param-description, jsdoc/require-param-type, jsdoc/require-returns-description */

/**
 *          - url - server URL that will be used for the request
 *          - method - request method to be used when making the request
 *          - baseURL - will be prepended to `url` unless `url` is absolute
 *          - headers - custom headers to be sent, ex. headers: {'X-Requested-With': 'XMLHttpRequest'}
 *          - params - URL parameters to be sent with the request. Must be a plain object or a URLSearchParams object
 *          - data - the data to be sent as the request body. Only applicable for request methods 'PUT', 'POST', and
 *          'PATCH'
 *              Must be of one of the following types: string, plain object, ArrayBuffer, ArrayBufferView,
 *              URLSearchParams, FormData, File, Blob
 *          - timeout - specifies the number of milliseconds before the request times out. Default is '0' (no timeout)
 *          - responseType - indicates the type of data that the server will respond with. Options are 'arraybuffer',
 *              'blob', 'document', 'json', 'text', 'stream'
 *          - requestIdParams - Used to showing/hiding loading indicator and calculating requestId which is used to
 *              aborting duplicated requests object with fields:
 *                  - functionName - name of the function from which axios is invoked
 *                  - componentName - name of the component from which axios is invoked
 *          - requestId - Precalculated id of a request used instead of requestIdParams
 *          calculating requestId which is used to aborting duplicated requests and setting the Accept-Language header
 *
 * @returns {Promise<*>} - Schema of response object:
 *          - data - response that was provided by the server
 *          - status - HTTP status code from the server response
 *          - statusText - HTTP status message from the server response
 *          - headers - the headers that the server responded with
 *          - config - the config that was provided to `axios` for the request
 *          - request - the request that generated this response (XMLHttpRequest instance)
 * @param response
 */

function mapSystemMessageDTO(response) {
    if (response.data?.systemMessage) {
        return {...response, data: {...response.data, ...response.data.systemMessage}};
    }
    return response;
}

export const exceedsUrlMaximumLength = (url) => url?.length > MAX_URL_LENGTH;

export const exceedsMaximumLength = (axiosException) => {
    const response = axiosException?.response;
    const url = axiosException?.config?.url;
    return response?.status === 414 || (!response && exceedsUrlMaximumLength(url));
};

const getTooLongUrlMessage = (axiosException) => {
    const urlLength = axiosException?.config?.url?.length;
    const urlLengthMessage = urlLength ? ` (number of characters ${urlLength})` : '';
    return `Cannot make a request, URL is to long${urlLengthMessage}.`;
};

async function axiosWrapper(additionalConfig, widgetInstance, store) {
    //case of auto refresh - prevents from displaying 401 erros when user is inactive.
    if (isTokenTimedOut()) {
        return Promise.reject(new Error(`Tried to make request when access token is expired: ${additionalConfig}`));
    }
    const source = (additionalConfig || {}).cancelTokenSource || CancelToken.source();
    const {
        headers,
        data,
        requestIdParams,
        requestId,
        method,
        shouldShowSystemMessage = true,
        shouldShowLoader = true,
        useOldTranslations = true,
        ...rest
    } = additionalConfig;
    const axiosConfig = getBasicAxiosConfig(headers, widgetInstance, source, method);
    const extendedAxiosConfig = getExtendedAxiosConfig(data, widgetInstance, requestIdParams, requestId, rest);

    showLoadingIndicator(widgetInstance, shouldShowLoader);

    _.extend(axiosConfig, getConfigForGlobalOptions(extendedAxiosConfig, store));
    abortIfDuplicate(extendedAxiosConfig.requestId);
    cacheRequest(extendedAxiosConfig.requestId, source);

    try {
        // Wrap an instance of axios
        // const zipkinAxios = wrapAxios(axios, {tracer, serviceName});
        // const response = await zipkinAxios.sendRequest(axiosConfig.url, axiosConfig);
        let response = await axios(axiosConfig);
        if (useOldTranslations) {
            response.data = await langUtils.translateObject(response.data);
        }
        response.data = await translateBlob(response.data, response.headers);
        response = mapSystemMessageDTO(response);

        if (utils.isSystemMessage(response.data) || utils.isLangServiceMessage(response.data)) {
            systemMessage.showMessage(response.data);
        }

        return response;
    } catch (e) {
        if (!axios.isCancel(e)) {
            if (exceedsMaximumLength(e)) {
                showSystemMessage(e, getTooLongUrlMessage(e));
                return Promise.reject(e);
            }
            showException(e, widgetInstance, shouldShowSystemMessage);
            throw e;
        }
        return Promise.reject(e);
    } finally {
        hideLoadingIndicator(widgetInstance, shouldShowLoader);
    }
}

export const getCancelToken = () => CancelToken;
export const {isCancel} = axios;

axiosWrapper.getCancelToken = getCancelToken;
axiosWrapper.isCancel = isCancel;

export default axiosWrapper;
