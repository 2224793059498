import _ from 'underscore';
import prepareAppId from './prepareAppId';
import ReduxStore from '../Redux/ReduxStore';
import {MODE} from '../actions/globalOptionsActions';

const {ALPHA} = MODE;

let {debugMode = false} = window.envConfig || {};

//global function for managing debugMode from browser console
window.enableDebugMode = (mode = true) => {
    debugMode = mode;
};

const checkConfigError = (eventConfig, appHandler) => {
    const fromModalText = appHandler.props.isModal ? ' from Modal' : '';

    if (!_.isEmpty(eventConfig.source) && eventConfig.allowedFromAllSources) {
        console.error(
            'Event ' +
                eventConfig.event +
                ' in app ' +
                appHandler.getEId() +
                fromModalText +
                ' has bad configuration: mix of event.source and allowedFromAllSources = true'
        );
    }
    if (!eventConfig.source && !eventConfig.allowedFromAllSources) {
        console.error(
            'Event ' +
                eventConfig.event +
                ' in app ' +
                appHandler.getEId() +
                fromModalText +
                ' has bad configuration: not defined event.source and allowedFromAllSources = false'
        );
    }
};

const logEvent = (name, data, source, isFromModal, appId) => {
    const store = ReduxStore.getState() || {};
    if (!debugMode && (store.globalOptions || {}).mode !== ALPHA) {
        return;
    }
    const time = new Date().toLocaleTimeString();
    if (appId) {
        //eslint-disable-next-line no-console
        console.timeStamp(`${appId} received event: ${name}`);
        console.groupCollapsed(`${time} ${appId} received event: ${name}`);
    } else {
        //eslint-disable-next-line no-console
        console.timeStamp(`${source} sent event: ${name}`);
        console.groupCollapsed(`${time} ${source} sent event: ${name}`);
    }
    //eslint-disable-next-line no-console
    console.log('Data: ', data);
    //eslint-disable-next-line no-console
    console.log('Additional information: ', {
        name,
        source,
        isFromModal
    });
    console.groupEnd();
};

const EventBus = {
    configuration: {},
    firePost(name, data, source, isFromModal) {
        logEvent(name, data, source, isFromModal);
        Object.keys(this.configuration).forEach((appId) =>
            this.executeOssEvent(appId, name, data, source, isFromModal)
        );
    },
    post(appHandler, name, data) {
        if (appHandler.props) {
            this.firePost(name, data, appHandler.getEId(), appHandler.props.isModal || false);
        } else {
            this.firePost(name, data, appHandler.getEId(), false);
        }
    },
    postToModal(appHandler, name, data) {
        this.firePost(name, data, appHandler.getEId(), true);
    },
    postToView(appHandler, name, data) {
        this.firePost(name, data, appHandler.getEId(), false);
    },
    subscribe(appHandler, config) {
        if (appHandler) {
            const appId = prepareAppId(appHandler);
            if (appId && appId != 'undefined') {
                this.configuration[appId] = {
                    events: config,
                    handler: appHandler,
                    isModal: appHandler.props.isModal
                };
                config.forEach((conf) => checkConfigError(conf, appHandler));
            }
        }
    },
    unsubscribe(appHandler) {
        const appId = prepareAppId(appHandler);
        if (this.configuration[appId]) {
            delete this.configuration[appId];
        }
    },
    executeOssEvent(appId, name, data, source, isFromModal) {
        const conf = this.configuration[appId];
        if (!conf) {
            return;
        }
        const executeEvent = (newEventName, actionData) => {
            let eventData;
            const eventName = newEventName || name;
            if (actionData) {
                if (Object.prototype.toString.call(actionData) === '[object Object]') {
                    eventData = _.extend({}, data, actionData);
                } else {
                    eventData = actionData;
                }
            } else {
                eventData = data;
            }
            logEvent(name, data, source, isFromModal, appId);
            conf.handler.onOssEvent(eventName, eventData, source);
        };

        for (let i = 0, len = conf.events.length; i < len; i++) {
            const eventConfig = conf.events[i];
            const eventMatch = eventConfig.event && eventConfig.event.indexOf(name) > -1;
            const sourceMatch = eventConfig.source && eventConfig.source.indexOf(source) > -1;
            if (eventMatch) {
                if (eventConfig.allowedFromAllSources) {
                    executeEvent(eventConfig.action, eventConfig.actionData);
                    break;
                }
                if (sourceMatch) {
                    executeEvent(eventConfig.action, eventConfig.actionData);
                    break;
                }
            }
        }
    }
};

window.debugApi = _.extend(window.debugApi || {}, {
    fireEvent: EventBus.firePost.bind(EventBus)
});

export default EventBus;
