import Markdown from 'markdown-it';
import footnotes from 'markdown-it-footnote';
import _ from 'underscore';

export const getInstance = (config = {}, plugins = {}) => {
    const md = new Markdown(config);
    if (plugins.footnotes) {
        md.use(footnotes);
    }
    return md;
};

export const manageFormats = (md, formats) => {
    const settings = {disabled: [], enabled: []};
    _.each(formats, (value, key) => {
        settings[value ? 'enabled' : 'disabled'].push(key);
    });
    md.disable(settings.disabled);
    md.enable(settings.enabled);
};
