import {setMeasurementProfile} from '@web-core/store';
import {SET_MEASUREMENT_PROFILE} from '../actions/actionsTypes';
import {MeasurementProfileDTO} from '../providers/MeasurementProfileProvider/types/MeasurementProfileTypes';

export default (
    state: MeasurementProfileDTO,
    action: {type: string; payload: {measurementProfile?: MeasurementProfileDTO}}
): MeasurementProfileDTO => {
    const {type, payload} = action || {};
    if (type === SET_MEASUREMENT_PROFILE) {
        setMeasurementProfile(payload?.measurementProfile?.name || '');
        return payload?.measurementProfile;
    }
    return state || null;
};
