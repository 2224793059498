import {SUBSCRIBE_EVENTS, SUBSCRIBE_EVENTS_CLEAR} from '../../actions/actionsTypes';

export default function (state = {}, action) {
    switch (action.type) {
        case SUBSCRIBE_EVENTS: {
            return {
                ...state,
                ...action.payload
            };
        }
        case SUBSCRIBE_EVENTS_CLEAR: {
            return {};
        }
        default:
            return state;
    }
}
