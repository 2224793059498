import {
    DeleteSystemMessageHandler,
    OldTranslateHandler,
    Settings,
    ShowSystemMessageHandler,
    Store,
    StoreKeycloakInstance,
    SystemMessageStore
} from './types';

const store: Store = {
    keycloakInstance: null,
    systemMessage: {
        showHandler: null,
        deleteHandler: null
    },
    localization: {
        current: '',
        oldTranslateHandler: null
    }
};

const settings: Settings = {
    isAlphaMode: false,
    isLoggingExtended: false,
    inContext: '',
    measurementProfile: ''
};

/**
 * Set keycloak instance used in framework after successful log in
 *
 * @param keycloak - keycloak instance for logged user
 */
export const setKeycloakInstance = (keycloak: StoreKeycloakInstance): void => {
    store.keycloakInstance = keycloak;
};

/**
 * Get keycloak instance for currently logged user
 *
 * @returns - logged user keycloak instance
 */
export const getKeycloakInstance = (): StoreKeycloakInstance => store.keycloakInstance;

/**
 * Set framework alpha mode flag (used for AB testing of experimental features)
 *
 * @param isAlpha - flag with information about alpha mode
 */
export const setAlphaMode = (isAlpha = false): void => {
    settings.isAlphaMode = isAlpha;
};

/**
 * Get information if alpha mode flag is set
 *
 * @returns - alpha mode flag
 */
export const isAlphaMode = (): boolean => settings.isAlphaMode;

/**
 * Set framework extended logging flag (used for extended debug)
 *
 * @param isLoggingExtended - flag with information about extended logging
 */
export const setLoggingExtended = (isLoggingExtended = false): void => {
    settings.isLoggingExtended = isLoggingExtended;
};

/**
 * Get information if extended logging is set
 *
 * @returns - extended logging flag
 */
export const isLoggingExtended = (): boolean => settings.isLoggingExtended;

/**
 * Set framework IN Context (QueryContext)
 *
 * * @param inContext - framework currently set query context string
 */
export const setInContext = (inContext: string): void => {
    settings.inContext = inContext;
};

/**
 * Get framework currently used query context string
 *
 * @returns - framework query context string
 */
export const getInContext = (): string => settings.inContext;

/**
 * Set framework measurement profile name
 *
 * @param newMeasurementProfile - profile name
 */
export const setMeasurementProfile = (newMeasurementProfile: string): void => {
    settings.measurementProfile = newMeasurementProfile;
};

/**
 * Get framework measurement profile name
 *
 * @returns - framework profile name
 */
export const getMeasurementProfile = (): string => settings.measurementProfile;

/**
 * Set framework system messages handlers
 *
 * @param showHandler - handler for showing messages
 * @param deleteHandler - handler for removing messages
 */
export const setSystemMessageHandlers = (
    showHandler: ShowSystemMessageHandler,
    deleteHandler: DeleteSystemMessageHandler
): void => {
    store.systemMessage.showHandler = showHandler;
    store.systemMessage.deleteHandler = deleteHandler;
};

/**
 * Get framework currently used query context string
 *
 * @returns - framework query context string
 */
export const getSystemMessageHandlers = (): SystemMessageStore => store.systemMessage;

/**
 * Set framework current localization
 *
 * @param localization - current localization
 */
export const setCurrentLocale = (localization: string): void => {
    store.localization.current = localization;
};

/**
 * Get framework current localization
 *
 * @returns - framework current localization
 */
export const getCurrentLocale = (): string => store.localization.current;

/**
 * Set framework handler for old translations
 *
 * @param oldTranslateHandler - dictionary handler
 */
export const setOldTranslateHandler = (oldTranslateHandler: OldTranslateHandler): void => {
    store.localization.oldTranslateHandler = oldTranslateHandler;
};

/**
 * Get framework old translations handler
 *
 * @returns - framework dictionary handler
 */
export const getOldTranslateHandler = (): OldTranslateHandler => store.localization.oldTranslateHandler;
