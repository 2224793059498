import {
    DataDefinitions,
    DataDefinitionsPayload,
    DataTypePayload,
    DataValuePayload,
    FilterDefinitions,
    FilterDefinitionsPayload,
    FilterValuePayload,
    ParametersDataType,
    ParametersDefinitions,
    ParametersValues,
    UnregisterWidgetPayload,
    WidgetDataValue,
    WidgetFilterValue,
    WidgetId
} from './types/WidgetParametersTypes';

export const registerDefinitionOfFilter = (
    registeredFilters: FilterDefinitions,
    payload: FilterDefinitionsPayload
): FilterDefinitions => {
    return {
        ...(registeredFilters || {}),
        [payload.type]: {
            widgetIds: [...new Set([...(registeredFilters?.[payload.type]?.widgetIds || []), payload.widgetId])]
        }
    };
};

export const registerDefinitionOfData = (
    registeredData: DataDefinitions,
    payload: DataDefinitionsPayload
): DataDefinitions => {
    return {
        ...(registeredData || {}),
        [payload.widgetId]: payload.params
    };
};

export const registerDataType = (dataType: ParametersDataType, payload: DataTypePayload): ParametersDataType => {
    return {
        ...(dataType || {}),
        [payload.type]: {
            widgetIds: [...new Set([...(dataType?.[payload.type]?.widgetIds || []), payload.widgetId])]
        }
    };
};

export const addFilterValue = (filtersValue: WidgetFilterValue, payload: FilterValuePayload): WidgetFilterValue => {
    return {
        ...filtersValue,
        [payload.widgetId]: {
            ...(filtersValue?.[payload.widgetId] || {}),
            [payload.type]: {
                value: payload.value
            }
        }
    };
};

export function removeWidgetFromWidgetIds<T extends Record<string, {widgetIds: WidgetId[]}>>(
    data: T,
    payload: UnregisterWidgetPayload
): {[p: string]: {widgetIds: WidgetId[]}} {
    const {widgetIds = []} = data?.[payload.type] || {};
    const widgetToRemoveIdx = widgetIds.findIndex((id: string): boolean => id === payload.widgetId);
    const nextWidgetIds = [...widgetIds];
    nextWidgetIds.splice(widgetToRemoveIdx, 1);

    if (nextWidgetIds.length === 0) {
        const {[payload.type]: typeToRemove, ...rest} = data;

        return {
            ...(rest || {})
        };
    }

    return {
        ...(data || {}),
        [payload.type]: {
            widgetIds: nextWidgetIds
        }
    };
}

function removeWidgetById<T extends Record<string, unknown>>(values: T, widgetId: WidgetId): Omit<T, string> {
    const {[widgetId]: widgetToRemove, ...rest} = values || ({} as T);
    return rest;
}

export const removeDefinitionsForWidget = (
    definitions: ParametersDefinitions,
    payload: UnregisterWidgetPayload
): ParametersDefinitions => {
    return {
        filter: removeWidgetFromWidgetIds<FilterDefinitions>(definitions?.filter, payload),
        data: removeWidgetById<DataDefinitions>(definitions?.data, payload.widgetId)
    };
};
export const removeValuesForWidget = (values: ParametersValues, widgetId: WidgetId): ParametersValues => {
    return {
        filter: removeWidgetById<WidgetFilterValue>(values?.filter, widgetId),
        data: removeWidgetById<WidgetDataValue>(values?.data, widgetId)
    };
};

export const addDataValue = (dataValue: WidgetDataValue, payload: DataValuePayload): WidgetDataValue => {
    return {
        ...(dataValue || {}),
        [payload.widgetId]: payload.values
    };
};
