import {WIDGET_PARAMETERS_ACTIONS} from '../../actions/actionsTypes';
import {
    addDataValue,
    addFilterValue,
    registerDataType,
    registerDefinitionOfData,
    registerDefinitionOfFilter,
    removeDefinitionsForWidget,
    removeValuesForWidget,
    removeWidgetFromWidgetIds
} from './registerReducerHelpers';
import {
    DataDefinitionsPayload,
    FilterDefinitionsPayload,
    WidgetParametersState,
    FilterValuePayload,
    DataValuePayload,
    UnregisterWidgetPayload,
    WidgetParametersAction,
    DataTypePayload,
    ParametersDataType
} from './types/WidgetParametersTypes';

const DEFAULT_STATE: WidgetParametersState = {
    dataType: {},
    definitions: {
        data: {},
        filter: {}
    },
    values: {
        data: {},
        filter: {}
    }
};

export default (state: WidgetParametersState, {type, payload}: WidgetParametersAction): WidgetParametersState => {
    switch (type) {
        case WIDGET_PARAMETERS_ACTIONS.REGISTER_FILTER: {
            return {
                ...state,
                definitions: {
                    ...state.definitions,
                    filter: registerDefinitionOfFilter(state?.definitions?.filter, payload as FilterDefinitionsPayload)
                }
            };
        }
        case WIDGET_PARAMETERS_ACTIONS.REGISTER_DATA: {
            return {
                ...state,
                definitions: {
                    ...state.definitions,
                    data: registerDefinitionOfData(state?.definitions?.data, payload as DataDefinitionsPayload)
                }
            };
        }
        case WIDGET_PARAMETERS_ACTIONS.REGISTER_DATA_TYPE: {
            return {
                ...state,
                dataType: registerDataType(state.dataType, payload as DataTypePayload)
            };
        }
        case WIDGET_PARAMETERS_ACTIONS.ADD_FILTER_VALUE: {
            return {
                ...state,
                values: {
                    ...state.values,
                    filter: addFilterValue(state?.values?.filter, payload as FilterValuePayload)
                }
            };
        }
        case WIDGET_PARAMETERS_ACTIONS.ADD_DATA_VALUE: {
            return {
                ...state,
                values: {
                    ...state.values,
                    data: addDataValue(state?.values?.data, payload as DataValuePayload)
                }
            };
        }
        case WIDGET_PARAMETERS_ACTIONS.UNREGISTER_WIDGET_PARAMETERS: {
            return {
                ...state,
                definitions: removeDefinitionsForWidget(state?.definitions, payload as UnregisterWidgetPayload),
                values: removeValuesForWidget(state?.values, (payload as UnregisterWidgetPayload).widgetId)
            };
        }
        case WIDGET_PARAMETERS_ACTIONS.UNREGISTER_WIDGET_DATA_TYPE: {
            return {
                ...state,
                dataType: removeWidgetFromWidgetIds<ParametersDataType>(
                    state?.dataType,
                    payload as UnregisterWidgetPayload
                )
            };
        }
        default:
            return state || DEFAULT_STATE;
    }
};
