import {SET_CUSTOM_DATA, ERASE_CUSTOM_DATA} from '../../../actions/actionsTypes';

export default function (state = {}, {type, payload}) {
    switch (type) {
        case SET_CUSTOM_DATA: {
            const widgetId = Object.keys(payload)[0];
            const customWidgets = {...state.customWidgets};
            customWidgets[widgetId] = payload[widgetId];
            return {
                ...state,
                customWidgets
            };
        }
        case ERASE_CUSTOM_DATA: {
            const widgetId = payload.widgetId;
            const customWidgets = {...state.customWidgets};
            delete customWidgets[widgetId];

            return {
                ...state,
                customWidgets
            };
        }
        default:
            return state;
    }
}
