import {INPUTS_CHANGED, INPUTS_CLEARED, SET_NEW_INPUTS} from '../../actions/actionsTypes';

export default function (state = {}, {type, payload}) {
    switch (type) {
        case INPUTS_CHANGED: {
            const newState = {...state};
            Object.keys(payload).forEach((key) => {
                newState[key] = {
                    ...state[key],
                    ...payload[key]
                };
            });
            return newState;
        }
        case SET_NEW_INPUTS: {
            const newState = {...state};
            Object.keys(payload).forEach((key) => {
                newState[key] = {
                    ...payload[key]
                };
            });
            return newState;
        }
        case INPUTS_CLEARED: {
            const newState = {
                ...state
            };
            Object.keys(payload).forEach((key) => {
                delete newState[key];
            });
            return newState;
        }
        default:
            return state;
    }
}
