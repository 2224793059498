import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faAngleDoubleDown,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleDoubleUp,
    faAngleLeft,
    faAngleRight,
    faArrowAltCircleDown,
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faArrowAltCircleUp,
    faCaretLeft,
    faCaretSquareDown,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faClock,
    faCogs,
    faColumns,
    faEdit,
    faFileImport,
    faFolder,
    faGlobeAfrica,
    faGripVertical,
    faHome,
    faList,
    faLongArrowAltDown,
    faLongArrowAltLeft,
    faLongArrowAltRight,
    faLongArrowAltUp,
    faMap,
    faPaperclip,
    faPen,
    faPlus,
    faPollH,
    faReply,
    faReplyAll,
    faSearchPlus,
    faSlidersH,
    faSortDown,
    faSortUp,
    faTable,
    faUserTimes,
    faWindowMinimize,
    faWindowRestore
} from '@fortawesome/free-solid-svg-icons';

// If you want to add new icon from font-awesome-5
// you should import it from above directory and then add to below library object.
library.add(
    faAngleDoubleDown,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleDoubleUp,
    faAngleLeft,
    faAngleRight,
    faArrowAltCircleDown,
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faArrowAltCircleUp,
    faCaretLeft,
    faCaretSquareDown,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faClock,
    faCogs,
    faColumns,
    faEdit,
    faFileImport,
    faFolder,
    faGlobeAfrica,
    faGripVertical,
    faHome,
    faList,
    faLongArrowAltDown,
    faLongArrowAltLeft,
    faLongArrowAltRight,
    faLongArrowAltUp,
    faMap,
    faPaperclip,
    faPen,
    faPlus,
    faPollH,
    faReply,
    faReplyAll,
    faSearchPlus,
    faSlidersH,
    faSortDown,
    faSortUp,
    faTable,
    faUserTimes,
    faWindowMinimize,
    faWindowRestore
);
