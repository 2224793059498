import {TypeDTO} from '@web-core/graphql';
import {
    MODEL_TYPES_FETCH_START,
    MODEL_TYPES_FETCH_DONE,
    MODEL_TYPES_FETCH_FAIL,
    MODEL_TYPES_ATTRIBUTES_FETCH_START,
    MODEL_TYPES_ATTRIBUTES_FETCH_DONE,
    MODEL_TYPES_ATTRIBUTES_FETCH_FAIL
} from '../actions/model/modelActions';
import {DEFAULT_KEY} from '../hooks/useModel/modelHelpers';
import {Model, TypeModel} from '../Redux/types/storeTypes';
import {
    FetchTypesActionPayload,
    FetchTypesActionPayloadWithData,
    FetchTypesAttributesActionPayload,
    FetchTypesAttributesActionPayloadWithData,
    ModelActions
} from '../actions/model/types/modelActionsTypes';

const replaceTypesState = (
    state: Model,
    types: string[],
    replacer: (typeModel: TypeModel, type: string) => TypeModel
): Model => {
    return (types || []).reduce(
        (result: Model, type: string): Model => ({
            ...result,
            [type]: {
                ...replacer(state?.[type] || {}, type)
            }
        }),
        state || {}
    );
};

const fetchStateReducer = (
    state: Model,
    payload: FetchTypesActionPayload | FetchTypesAttributesActionPayload,
    getNewState: (type: string) => TypeModel,
    removeAttributes?: string[]
): Model => {
    const {types} = payload;
    let key = DEFAULT_KEY;
    if ('cacheKey' in payload) {
        key = payload.cacheKey || DEFAULT_KEY;
    }
    const replacer = (currentState: TypeModel, type: string): TypeModel => {
        const result = {
            ...currentState,
            ...getNewState(type)
        };
        (removeAttributes || []).forEach((attribute: string): void => {
            delete result[attribute];
            delete result?.attributes?.[key]?.[attribute];
        });
        return result;
    };
    return replaceTypesState(state, types, replacer);
};

const fetchTypeStartReducer = (state: Model, payload: FetchTypesActionPayload): Model => {
    return fetchStateReducer(
        state,
        payload,
        (): Partial<TypeModel> => ({
            loadingType: true
        }),
        ['loadingTypeFail']
    );
};

const fetchTypeAttributesStartReducer = (state: Model, payload: FetchTypesAttributesActionPayload): Model => {
    const {cacheKey} = payload;
    const key = cacheKey || DEFAULT_KEY;
    return fetchStateReducer(
        state,
        payload,
        (type: string): Partial<TypeModel> => ({
            attributes: {
                ...state?.[type]?.attributes,
                [key]: {
                    ...state?.[type]?.attributes?.[key],
                    loadingAttributes: true
                }
            }
        }),
        ['loadingAttributesFail']
    );
};

const fetchTypesFailReducer = (state: Model, payload: FetchTypesActionPayload): Model => {
    return fetchStateReducer(
        state,
        payload,
        (): Partial<TypeModel> => ({
            loadingType: false,
            loadingTypeFail: true
        })
    );
};

const fetchTypesAttributesFailReducer = (state: Model, payload: FetchTypesAttributesActionPayload): Model => {
    const {cacheKey} = payload;
    const key = cacheKey || DEFAULT_KEY;
    return fetchStateReducer(
        state,
        payload,
        (type: string): Partial<TypeModel> => ({
            attributes: {
                ...state?.[type]?.attributes,
                [key]: {
                    ...state?.[type]?.attributes?.[key],
                    loadingAttributes: false,
                    loadingAttributesFail: true
                }
            }
        })
    );
};

const fetchTypesDoneReducer = (state: Model, payload: FetchTypesActionPayloadWithData): Model => {
    const {data} = payload;
    return fetchStateReducer(
        state,
        payload,
        (type: string): Partial<TypeModel> => ({
            loadingType: false,
            type: (data || []).find((typeDTO: TypeDTO): boolean => typeDTO?.name === type) || null
        })
    );
};

const fetchTypesAttributesDoneReducer = (state: Model, payload: FetchTypesAttributesActionPayloadWithData): Model => {
    const {data, cacheKey} = payload;
    const key = cacheKey || DEFAULT_KEY;
    if (cacheKey) {
        return fetchStateReducer(
            state,
            payload,
            (type: string): Partial<TypeModel> => ({
                attributes: {
                    ...state?.[type]?.attributes,
                    [key]: {
                        ...state?.[type]?.attributes?.[key],
                        data: data?.[type] || [],
                        loadingAttributes: false
                    }
                }
            })
        );
    }
    return fetchStateReducer(
        state,
        payload,
        (type: string): Partial<TypeModel> => ({
            attributes: {
                ...state?.[type]?.attributes,
                [key]: {
                    ...state?.[type]?.attributes?.[key],
                    data: data?.[type] || [],
                    loadingAttributes: false
                }
            }
        })
    );
};

export default function (state: Model, action: ModelActions): Model {
    const {type, payload} = action || {};
    switch (type) {
        case MODEL_TYPES_FETCH_START:
            return fetchTypeStartReducer(state || {}, payload);
        case MODEL_TYPES_FETCH_DONE:
            return fetchTypesDoneReducer(state || {}, payload);
        case MODEL_TYPES_FETCH_FAIL:
            return fetchTypesFailReducer(state || {}, payload);
        case MODEL_TYPES_ATTRIBUTES_FETCH_START:
            return fetchTypeAttributesStartReducer(state || {}, payload);
        case MODEL_TYPES_ATTRIBUTES_FETCH_DONE:
            return fetchTypesAttributesDoneReducer(state || {}, payload);
        case MODEL_TYPES_ATTRIBUTES_FETCH_FAIL:
            return fetchTypesAttributesFailReducer(state || {}, payload);
        default:
            return state || {};
    }
}
