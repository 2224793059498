// View actions
export const SET_VIEW_AS_BOOKMARK = 'SET_VIEW_AS_BOOKMARK';
export const UNSET_VIEW_AS_BOOKMARK = 'UNSET_VIEW_AS_BOOKMARK';
export const START_LOADING_BOOKMARK_CONFIG = 'START_LOADING_BOOKMARK_CONFIG';
export const STOP_LOADING_BOOKMARK_CONFIG = 'STOP_LOADING_BOOKMARK_CONFIG';
export const CLEAR_STORE = 'CLEAR_STORE';
export const CLEAR_WIDGET_PROPERTIES_FROM_MODAL = 'CLEAR_WIDGET_PROPERTIES_FROM_MODAL';
export const SET_VIEW_ID = 'SET_VIEW_ID';
export const SET_VIEW_CONFIG = 'SET_VIEW_CONFIG';
export const UPDATE_VIEW_CONFIG = 'UPDATE_VIEW_CONFIG';
export const REMOVE_VIEW_CONFIG = 'REMOVE_VIEW_CONFIG';
export const SET_ACTIVE_VIEW = 'SET_ACTIVE_VIEW_ID';
export const OPEN_MODALS = 'OPEN_MODALS';
export const CLOSE_MODALS = 'CLOSE_MODALS';
export const OPEN_PROMPTS = 'OPEN_PROMPTS';
export const CLOSE_PROMPTS = 'CLOSE_PROMPTS';
export const SET_VIDEOWALL_MODE = 'SET_VIDEOWALL_MODE';
export const UNSET_VIDEOWALL_MODE = 'UNSET_VIDEOWALL_MODE';

// Bookmark popup
export const OPEN_BOOKMARK_POPUP = 'OPEN_BOOKMARK_POPUP';
export const CLOSE_BOOKMARK_POPUP = 'CLOSE_BOOKMARK_POPUP';

// Filter actions
export const FILTER_CHANGED = 'FILTER';

// Selection action
export const SELECTED_OBJECTS_CHANGED = 'SELECTED_OBJECTS_CHANGED';
export const REMOVE_SELECTED_OBJECTS_CHANGED = 'REMOVE_SELECTED_OBJECTS_CHANGED';

// Table actions
export const RESIZE_COLUMNS = 'RESIZE_COLUMNS';
export const REORDER_COLUMNS = 'REORDER_COLUMNS';
export const ERASE_PREFERENCES = 'ERASE_PREFERENCES';

// Form sctions
export const INPUTS_CHANGED = 'INPUTS_CHANGED';
export const INPUTS_CLEARED = 'INPUTS_CLEARED';
export const SET_NEW_INPUTS = 'SET_NEW_INPUTS';

// Data operations
export const SET_DATA = 'SET_DATA';

// Tabs actions
export const CURRENT_TAB_CHANGED = 'CURRENT_TAB_CHANGED';

// Context buttons actions
export const CONTEXT_BUTTONS_REQUESTED = 'CONTEXT_BUTTONS_REQUESTED';
export const CONTEXT_BUTTONS_DOWNLOADED = 'CONTEXT_BUTTONS_DOWNLOADED';
export const CONTEXT_BUTTONS_DOWNLOAD_FAIL = 'CONTEXT_BUTTONS_DOWNLOAD_FAIL';

// Optimistic update
export const OPTIMISTIC_UPDATE = 'OPTIMISTIC_UPDATE';

// Navigation
export const GET_FOLDERS_NAVIGATION = 'GET_FOLDERS_NAVIGATION';
export const TOGGLE_FAVOURITE = 'TOGGLE_FAVOURITE';
export const SET_UPDATED_FAVOURITES = 'SET_UPDATED_FAVOURITES';

// Language actions
export const LANGUAGE_CHANGE = 'LANGUAGE_CHANGE';

// Decoration actions
export const DECORATION_CHANGED = 'DECORATION_CHANGED';
export const DECORATION_REMOVED = 'DECORATION_REMOVED';

//Global settings actions
export const ALPHA_MODE_CHANGE = 'ALPHA_MODE_CHANGE';
export const SOUND_NOTIFICATIONS_CHANGE = 'SOUND_NOTIFICATIONS_CHANGE';
export const OSS_CONSOLE_CONFIGURATOR = 'OSS_CONSOLE_CONFIGURATOR';
export const LOGGING_LEVEL_CHANGE = 'LOGGING_LEVEL_CHANGE';
export const EXTEND_LOGGING_CHANGE = 'EXTEND_LOGGING_CHANGE';

// Custom widgets actions
export const SET_CUSTOM_DATA = 'SET_CUSTOM_DATA';
export const ERASE_CUSTOM_DATA = 'ERASE_CUSTOM_DATA';

export const TOUCHED_OBJECTS = 'TOUCHED_OBJECTS';
export const SUBSCRIBE_EVENTS = 'SUBSCRIBE_EVENTS';
export const SUBSCRIBE_EVENTS_CLEAR = 'SUBSCRIBE_EVENTS_CLEAR';

export const SET_ACTIVE_DASHBOARD = 'SET_ACTIVE_DASHBOARD';
export const SET_FAVOURITE_LIST = 'SUBSCRIBE_EVENTS';
export const SET_DASHBOARD_BUTTONS_LIST = 'SET_DASHBOARD_BUTTONS_LIST';

export const SET_MEASUREMENT_PROFILE = 'SET_MEASUREMENT_PROFILE';

export const WIDGET_PARAMETERS_ACTIONS = {
    REGISTER_FILTER: 'REGISTER_FILTER',
    REGISTER_DATA: 'REGISTER_DATA',
    REGISTER_DATA_TYPE: 'REGISTER_DATA_TYPE',
    ADD_FILTER_VALUE: 'ADD_FILTER_VALUE',
    ADD_DATA_VALUE: 'ADD_DATA_VALUE',
    UNREGISTER_WIDGET_PARAMETERS: 'UNREGISTER_WIDGET_PARAMETERS',
    UNREGISTER_WIDGET_DATA_TYPE: 'UNREGISTER_WIDGET_DATA_TYPE'
} as const;
