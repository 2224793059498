import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import reduxThunk from 'redux-thunk';

import rootReducer from '../Reducers/rootReducer';

const middleware = applyMiddleware(reduxThunk);
const composeEnhancers = composeWithDevTools({trace: true, traceLimit: 50});

const store = createStore(
    rootReducer, {},
    composeEnhancers(middleware)
);

export default store;
