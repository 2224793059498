import {FILTER_CHANGED} from '../../actions/actionsTypes';

export default function (state = {}, action) {
    switch (action.type) {
        case FILTER_CHANGED: {
            const widgetsChunk = {...state};

            const widgetIDs = Object.keys(action.payload);
            widgetIDs.forEach((widgetID) => {
                const newValue = action.payload[widgetID];
                const oldValue = widgetsChunk[widgetID] || {};
                widgetsChunk[widgetID] = {
                    ...oldValue,
                    filter: newValue.filter || newValue
                };
            });

            return {
                ...state,
                ...widgetsChunk
            };
        }
        default:
            return state;
    }
}
