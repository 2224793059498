export const SEARCH_DELAY = 275;

export const SESSION_STORAGE_KEYS = {
    DICTIONARIES: 'dictionaries'
};

export const ROLES = {
    PERMANENT_TOKEN: 'permanent_token',
    DEVELOPER_MODE: 'developer',
    SOUND_NOTIFICATION: 'sound_notifications',
    OSS_CONSOLE_CONFIGURATOR: 'oss_console_configurator',
    OSS_CONSOLE_TENANT_CONFIGURATOR: 'oss_console_tenant_configurator',
    TRUSTED_SERVICE: 'TRUSTED_SERVICE',
    BPM_PROCESSES_VIEWER: 'BPM_PROCESSES_VIEWER',
    BPM_ADMIN: 'BPM_ADMIN'
};

export const PERSPECTIVES = {
    PLAN: 'PLAN'
};

export const SCREEN_BREAKPOINTS = {
    TABLET: 992,
    DESKTOP_1200: 1200,
    DESKTOP_1400: 1400
};

export const WIDGET_TYPES = {
    GQ_TREE_WIDGET: 'GQ_TREE_WIDGET',
    GQ_TABLE_WIDGET: 'GQ_TABLE_WIDGET',
    GQ_TREE_TABLE_WIDGET: 'GQ_TREE_TABLE_WIDGET',
    PROPERTY_PANEL: 'PROPERTY_PANEL',
    MAP: 'MAP',
    TABS: 'TABS',
    ROLLOUT_PANEL: 'ROLLOUT_PANEL',
    BUSINESS_COMPONENT_PLACEHOLDER: 'BUSINESS_COMPONENT_PLACEHOLDER',
    TABLE_WIDGET: 'TABLE_WIDGET',
    CUSTOM: 'CUSTOM'
};

const WIDGET_TYPES_CONST = {...WIDGET_TYPES} as const;

export type WidgetTypes = typeof WIDGET_TYPES_CONST[keyof typeof WIDGET_TYPES_CONST];
