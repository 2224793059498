import _ from 'underscore';

import {browser, cookie, dom, keycloak, other, regexp} from '@web-core/utils';

import * as markdown from './markdownUtils';
import {deepCloneObject as deepCloneObjectFunc} from './objectHelpers';
import {keycloakInstance} from '../keycloakInstance';

/**
 * @module frameworkUtils
 * @category utils
 */

const ID_REGEXP = /^[a-z0-9_]+$/;

let modalHash = '';

let ViewStore = {};
let queryParams = {};
const SYSTEM_MESSAGE_IDENTIFIER = 'SYSTEM_MESSAGE';
const CODE = 'code';
const MESSAGE_TYPE = 'messageType';
const OSS_ADMIN_USERNAME = 'ossadmin';
const EMPTY_VALUE_SIGN = '-';
const REPLACE_PATTERN = '{%s}';

export const PORTS = {
    HTTPS: ':25081',
    HTTP: ':25080'
};

export const SECURED_PROTOCOL = 'https:';

export const {getPlaceholdersRegExp} = regexp;
export const deepCloneObject = deepCloneObjectFunc;
export const {generateUUID, hashCode, uniqueId, isNumber} = other;
export const {getCookie, setCookie} = cookie;
export const {closest, getBoundingClientRectFromRef, toPx} = dom;
export const {getBrowserInformation} = browser;

export const getUserId = () => keycloak.getUserId(keycloakInstance);
export const getUserIdFromToken = () => keycloak.getUserIdFromToken(keycloakInstance);
export const getUserName = () => keycloak.getUserName(keycloakInstance);

export const isOSSAdmin = () => {
    return getUserName() === OSS_ADMIN_USERNAME || false;
};

export const setQueryParamsHandler = (queryParamsHandler) => {
    queryParams = queryParamsHandler;
};

export const setModalHash = (_modalHash) => {
    modalHash = _modalHash;
};

export const getModalHash = () => {
    return modalHash;
};

export const urlWithQCParams = (url, qcParams) => {
    const queryContextparams = qcParams || queryParams?.getQCQueryParams?.();
    if (!/http(s)?/.test(url) && !/perspective/gi.test(url) && queryParams.addListOfQueryParams) {
        return queryParams.addListOfQueryParams(url || '', queryContextparams);
    }
    return url;
};

export const setViewStore = (viewStore) => {
    if (_.isEmpty(ViewStore)) {
        ViewStore = viewStore;
    } else {
        console.error('View Store can be set only once!');
    }
};
export const isViewStoreEmpty = () => {
    return _.isEmpty(ViewStore);
};

export const isModalOpenedByUrl = () => {
    // eslint-disable-next-line no-restricted-globals
    const {hash} = location;
    return hash.indexOf('/modal') > -1 || hash.indexOf('/prompt') > -1;
};

export const quitModalOpenedByUrl = () => {
    /* eslint-disable no-restricted-globals */
    setModalHash('');
    const {hash} = location;
    const modalName = hash.indexOf('/modal') !== -1 ? '/modal' : '/prompt';
    const modalIndex = hash.indexOf(modalName);
    if (~modalIndex && !_.isEmpty(ViewStore)) {
        const qcParams = queryParams.getQCQueryParams();
        const cutLink = hash.substring(0, modalIndex);
        location.hash = queryParams.add(cutLink, qcParams);
    }
    /* eslint-enable no-restricted-globals */
};

export const quitModal = function () {
    if (isModalOpenedByUrl()) {
        //backward compatibility
        quitModalOpenedByUrl();
    } else if (!_.isEmpty(ViewStore)) {
        //nested modals closing
        ViewStore.closeModalView();
    }
};

export const quitSinglePrompt = () => {
    if (!_.isEmpty(ViewStore)) {
        ViewStore.closeSinglePrompt();
    }
};

export const quitPromptByPath = (viewPath) => {
    if (!_.isEmpty(ViewStore)) {
        ViewStore.closePromptByPath(viewPath);
    }
};

export const isLangServiceMessage = (data) => {
    return data && typeof data === 'object' && CODE in data && MESSAGE_TYPE in data;
};

export const isSystemMessage = (data) => {
    return data && data.internalIdentifier === SYSTEM_MESSAGE_IDENTIFIER;
};

export const getValidViewIdFromPath = (path) => {
    let newViewId = path;
    if (newViewId.indexOf('/#') === 0 || newViewId.indexOf('#/') === 0) {
        newViewId = newViewId.substr(2);
    }
    if (newViewId.indexOf('#') === 0) {
        // delete first #
        newViewId = newViewId.substring(1);
    }
    if (newViewId.indexOf('/') === 0) {
        // delete first /
        newViewId = newViewId.substring(1);
    }
    if (newViewId.indexOf('view/') === 0) {
        //delete 'view/'
        newViewId = newViewId.substring(5);
    }
    if (newViewId.indexOf('views/') === 0) {
        //delete 'view/'
        newViewId = newViewId.substring(6);
    }
    return newViewId;
};

export const shouldUseViewV2 = (path) => path.search(/\/?#?\/?views\//) === 0;

export const openModal = function (path, showAsPrompt, additionalConfig = {}) {
    const tmpPath = urlWithQCParams(path);
    if (showAsPrompt) {
        ViewStore.openPromptView(tmpPath, additionalConfig);
    } else {
        ViewStore.openModalView(tmpPath, additionalConfig);
    }
};

export const setParams = function (params) {
    return () => (params ? '?' + params : '');
};

export const getParams = () => '';

export const getParamsFromView = () => getParams();

export const showSystemAlert = (text) => {
    const alert = document.createElement('div');
    alert.className = 'OSSSystemAlert';
    /* eslint-disable */
    const alertIcon = `<i class='fa fa-exclamation-triangle fa-2x' aria-hidden='true'></i>`;
    const closeIcon = `<i class='fa fa-times' aria-hidden='true' onclick='document.body.removeChild(this.parentNode)'></i>`;
    /* eslint-enable */
    alert.innerHTML = `${alertIcon}${text}${closeIcon}`;

    document.body.appendChild(alert);
};

export const fixedURIDecoding = (uri) => {
    let isEncoded = false;
    try {
        // prevent chrome from returning 'url malformed' error when user write for example: 'ajax%'
        // (can't decode single % character)
        if (decodeURIComponent(uri) !== uri) {
            isEncoded = true;
        }
    } catch (e) {
        isEncoded = false;
    }
    return isEncoded ? decodeURIComponent(uri) : uri;
};

export const fixedURIEncoding = (uri) => {
    const newUri = fixedURIDecoding(uri);
    return encodeURIComponent(newUri);
};

export const encodeParamsValues = (params) => {
    const re = /[^&?]*?=[^&?]*/gi;
    let found;
    let newParams = '';
    // eslint-disable-next-line no-cond-assign
    while ((found = re.exec(params)) !== null) {
        const [key, value] = found[0].split('=');
        newParams += key + '=' + fixedURIEncoding(value) + '&';
    }

    return newParams.slice(0, -1);
};

export const getRedirectUrl = (url) => {
    let tmpUrl = url;
    // check if url is local and miss #, if true add '#/' before url, else just redirect
    if (url.indexOf('view') === 0 || url.indexOf('/view') === 0) {
        // Remove '/' at the beginning of the url
        tmpUrl = `#/${tmpUrl.replace(/^\/view/, 'view')}`;
    }
    if (url.indexOf('/#') === 0) {
        tmpUrl = tmpUrl.substring(1);
    }
    return urlWithQCParams(tmpUrl);
};

export const getExternalRedirectUrl = (url) => {
    return urlWithQCParams(url);
};

export const getValidHref = (viewLink) => {
    if (viewLink && viewLink.indexOf('view') === 0) {
        return `#/${viewLink}`;
    }
    if (viewLink && viewLink.indexOf('/view') === 0) {
        return `#/${viewLink.slice(1)}`;
    }
    if (viewLink && viewLink.indexOf('rest/web') === 0) {
        return `#/view${viewLink.slice(8)}`;
    }
    if (viewLink && viewLink.indexOf('/rest/web') === 0) {
        return `#/view${viewLink.slice(9)}`;
    }
    return viewLink;
};

export const getModalRedirectUrl = (url = '') => {
    let newUrl = getRedirectUrl(url);
    newUrl = getValidHref(newUrl);
    if (newUrl.indexOf('#/view') < 0) {
        newUrl = '#/view' + (newUrl[0] === '/' ? newUrl : '/' + newUrl);
    }
    return urlWithQCParams(newUrl);
};

export const redirect = (url) => {
    ViewStore.redirect(url);
};

export const externalRedirect = (url) => {
    ViewStore.externalRedirect(url);
};

export const openNewTab = (url) => {
    if (!/http(s)?/.test(url)) {
        const newUrl = getValidHref(getRedirectUrl(url));
        window.open(`${document.location.origin}/${newUrl}`);
    } else {
        window.open(url);
    }
};

export const prepareValidLinkUrl = (url) => {
    if (!/http(s)?/.test(url)) {
        if (!/(\/)?#/.test(url)) {
            const tempUrl = url.startsWith('/') ? url.substring(1) : url;
            return `${window.location.origin}/${tempUrl}`;
        }
    }
    return url;
};

export const getQuitModalHash = () => {
    // eslint-disable-next-line no-restricted-globals
    const {hash} = location;
    const modalName = hash.indexOf('/modal') !== -1 ? '/modal' : '/prompt';
    const modalIndex = hash.indexOf(modalName);
    if (~modalIndex) {
        return hash.substring(0, modalIndex);
    }
    return hash;
};

export const getOpenModalHash = (path, showAsPrompt) => {
    // eslint-disable-next-line no-restricted-globals
    const {hash} = location;
    const modalName = showAsPrompt ? '/prompt' : '/modal';
    const modalIndex = hash.indexOf(modalName);
    if (~modalIndex) {
        return hash.substring(0, modalIndex) + modalName + '/' + path;
    }
    return hash + modalName + '/' + path;
};

export const eliminateDoubleSlash = (url) => {
    if (url.indexOf('://') > -1) {
        const tempUrl = url.split('://');
        tempUrl[1] = tempUrl[1].replace('//', '/');
        return tempUrl.join('://');
    }
    return url.replace('//', '/');
};

export const preparePathParams = (selectedData, url) => {
    const re = getPlaceholdersRegExp();
    let found;
    let resultUrl = url;
    // eslint-disable-next-line no-cond-assign
    while ((found = re.exec(url)) !== null) {
        // Prepare params from object
        let params = '';
        if (_.isArray(selectedData)) {
            // eslint-disable-next-line no-loop-func
            _.each(selectedData, (selectedObject) => {
                if (selectedObject.data[found[1]]) {
                    params += fixedURIEncoding(selectedObject.data[found[1]]) + ',';
                }
            });
        } else {
            params = selectedData.data[found[1]] + ',';
        }
        params = params.slice(0, -1);
        // Replace <%...%> with params
        resultUrl = resultUrl.replace(found[0], params);
        resultUrl = eliminateDoubleSlash(resultUrl);
    }
    return resultUrl;
};

export const getClosestViewportElement = (node) => {
    const viewportElement = $(node).closest('.translatedElementRoot ');

    if (viewportElement && viewportElement[0]) {
        return viewportElement[0];
    }
    return null;
};

/**
 * Append query parameters to given url
 *
 * @function
 * @param {string} url Given url
 * @param {*} params Parameters to add
 * @returns {string} New url
 */
export const appendParams = (url, params) => {
    const [rootUrl, oldParams = ''] = url.split('?'),
        newParams = Object.keys(params)
            .filter((key) => !!params[key])
            .map((key) => `${key}=${params[key]}`)
            .join('&');

    return `${rootUrl}?${oldParams}${oldParams && '&'}${newParams}`;
};

/**
 * Generate url with given query in data object.
 *
 * @function
 * @param {string} url - url to be modified
 * @param {object} dataObject - object holding data params and event query params
 * @returns {string} url
 */
export const prepareUrl = (url, dataObject) => {
    const {data, eventQueryParams} = dataObject || {};
    //append params from data object
    if (!_.isEmpty(data)) {
        return appendParams(url, data);
    }

    //append params from eventQueryParams object
    if (!_.isEmpty(eventQueryParams)) {
        return appendParams(url, eventQueryParams);
    }

    return url;
};

export const getValidRouterLink = (link) => {
    if (!link) {
        return link;
    }
    const viewLink = link.replace(/(\/#|#)/, '');
    if (viewLink && viewLink.indexOf('/') !== 0) {
        return `/${viewLink}`;
    }
    return viewLink;
};

export const mapInnerListData = (innerData) => {
    const result = {};
    innerData.forEach((item) => {
        //support for list items
        const {columnId, value} = item || {};
        if (columnId && value) {
            result[columnId] = value;
        } else {
            result[item] = item;
        }
    });
    return result;
};

export const dataMapper = (data = {}) => {
    if (!Array.isArray(data)) {
        return [];
    }

    const mappedData = [];
    data.forEach((object) => {
        let mappedObject = {};
        const mapOfObject = new Map(Object.entries(object));
        const objectId = mapOfObject.has('objectId') ? mapOfObject.get('objectId') : null;
        if (!_.isEmpty(objectId)) {
            mappedObject = {
                objectId
            };
        } else {
            return;
        }

        if (mapOfObject.has('data')) {
            let innerData = mapOfObject.get('data');
            if (innerData.objectId) {
                delete innerData.objectId;
                console.warn(
                    'Object data contains reserved attribute name "objectId". This attribute will be' +
                        ' overwritten. Object with wrong data: ',
                    object
                );
            }
            if (Array.isArray(innerData)) {
                innerData = mapInnerListData(innerData);
            }
            mappedObject = Object.assign(mappedObject, innerData);
        }
        const children = mapOfObject.has('children') ? dataMapper(mapOfObject.get('children')) : [];
        mappedData.push(mappedObject, ...children);
    });
    return mappedData;
};

export const mapData = (data = {}) => {
    if (!data || Array.isArray(data)) {
        return data;
    }
    const result = {};
    Object.entries(data).forEach(([key, value]) => {
        const paths = key.split('.');
        const newValue = typeof value === 'object' && value !== null && !Array.isArray(value) ? mapData(value) : value;

        if (paths.length === 1) {
            result[key] = newValue;
        } else if (paths.length > 1) {
            let current = result;
            paths.forEach((path, i) => {
                if (i < paths.length - 1) {
                    current[path] = current[path] || {};
                    current = current[path];
                } else {
                    current[path] = newValue;
                }
            });
        }
    });
    return result;
};

export const replaceLabelPattern = (label, labelPattern) => {
    if (label && labelPattern) {
        return '{{' + labelPattern.replace(REPLACE_PATTERN, label) + '}}';
    }
    return label;
};
export const getDataByPath = (stringPath, data = {}) => {
    if (!data) {
        return null;
    }
    const {labelPattern, linkPattern, label} = data;
    let value;
    const path = stringPath.split('.');
    let currentData = data;

    if (labelPattern && !linkPattern) {
        return replaceLabelPattern(label, labelPattern);
    }

    if (path.length === 1) {
        return currentData[path[0]];
    }

    while (path.length > 1) {
        const head = path.shift();

        if (!currentData[head]) {
            return null;
        }

        value = currentData[head];
        currentData = currentData[head];
    }
    if (Array.isArray(currentData)) {
        value = currentData.map((item) => item[path[0]]);
    } else {
        value = currentData[path[0]];
    }
    return value;
};

/**
 *  Method to get markdown format object
 *
 * @function
 * @returns {object} - markdown format object
 */
const getMarkdownFormats = () => {
    return {
        blockquote: false,
        code: false,
        fence: false,
        heading: false,
        hr: false,
        lheading: false,
        list: false,
        reference: false,
        autolink: false,
        backticks: false,
        emphasis: true,
        entity: false,
        escape: false,
        image: false,
        newline: false,
        table: false,
        html_block: false,
        html_inline: false,
        strikethrough: false
    };
};

/**
 *  Method to get markdown instance
 *
 * @function
 * @param {object} additionalConfig - markdown config
 * @returns {object} - markdown instance
 */
export const getMarkdownInstance = (additionalConfig = {}) => {
    const md = markdown.getInstance(additionalConfig);
    markdown.manageFormats(md, getMarkdownFormats());
    return md;
};

export const validateId = (id, soft = false) => {
    if (typeof id !== 'string' || !ID_REGEXP.test(id)) {
        const msg = `Id should contains only alphanumerical characters (lower case) or an underscore. Current id ${id}`;
        if (soft) {
            console.warn(msg);
        } else {
            throw new Error(msg);
        }
    }
};

/**
 *  Method handling empty/null/undefined values, it returns fallback '-' sign if the condition was met
 *
 * @function
 * @param {any} value - parameter to be processed by the method
 * @returns {string} processed value
 */
export const getValueWithFallback = (value) => {
    return typeof value !== 'undefined' && value !== null && value !== '' ? String(value) : EMPTY_VALUE_SIGN;
};

export const getKibanaURL = (traceId = '') => {
    // eslint-disable-next-line max-len
    return `/kibana/app/kibana#/discover?_g=(refreshInterval:(pause:!t,value:0),time:(from:now-4h,to:now))&_a=(columns:!(log-level,log-service,log-msg,log-traceId),interval:auto,query:(language:kuery,query:'log-traceId%20:%20%22${traceId}%22'),sort:!(!('@timestamp',desc),!(log-level,asc)))`;
};

export const replacePort = (url) => {
    const {protocol} = window.location;

    if (protocol === SECURED_PROTOCOL) {
        return url.replace(PORTS.HTTP, PORTS.HTTPS);
    }
    return url;
};

/**
 * Returns data from event
 *
 * @function
 * @param {object} eventData - given data from event
 * @returns {object} - return data from event or empty object
 */
export const getDataParams = (eventData) => {
    if (Object.keys(eventData?.data || {}).length) {
        return eventData.data;
    }
    if (Object.keys(eventData?.eventQueryParams || {}).length) {
        return eventData.eventQueryParams;
    }
    return {};
};

export const isBoolean = (value) => value === false || value === true;

/**
 * Checks whether value is a string or not
 *
 * @function
 * @param {any} value - given value
 * @returns {boolean} - result of type check
 */
export const isString = (value) => {
    return typeof value === 'string';
};

/**
 * Returns a sum of an array of numbers, skips elements that arent numbers
 *
 * @function
 * @param {Array} arrayOfNumbers - array of numbers to sum
 * @returns {number} - sum
 */
export const sum = (arrayOfNumbers) => {
    if (!Array.isArray(arrayOfNumbers)) {
        return 0;
    }
    return arrayOfNumbers.reduce((sum, number) => {
        if (!isNumber(number)) {
            return sum;
        }
        return sum + number;
    }, 0);
};

export const noop = (_) => _;

/**
 * Returns fields with hidden attributes
 *
 * @function
 * @param {Array} fields - fields
 * @param {Array} hiddenAttributes - hidden attributes
 * @returns {Array} - components with hidden attributes without duplicates
 */
export const prepareFields = (fields = [], hiddenAttributes = []) => {
    const filteredHiddenAttributes = hiddenAttributes.reduce((hiddenAttributes, attr) => {
        if (!fields.includes(attr?.path)) {
            return [...hiddenAttributes, attr?.path];
        }
        return hiddenAttributes;
    }, []);

    return [...fields, ...filteredHiddenAttributes];
};

export const getParentsPaths = (path) => {
    const parentsPaths = [];
    const splittedPath = path.split('.');
    let lastParent = '';
    for (let i = 0; i < splittedPath.length - 1; i++) {
        if (lastParent.length === 0) {
            lastParent += splittedPath[i];
        } else {
            lastParent += '.' + splittedPath[i];
        }
        parentsPaths.push(lastParent);
    }
    parentsPaths.reverse();
    return parentsPaths;
};

export const prepareDataPath = (dataPath, prefix) => {
    if (Array.isArray(dataPath) && dataPath.length) {
        const preparedDataPath = (dataPath || []).join('.') || '';
        return prefix ? `${prefix}.${preparedDataPath}` : preparedDataPath;
    }
    if (dataPath && typeof dataPath === 'string') {
        return prefix ? `${prefix}.${dataPath}` : dataPath;
    }

    return '';
};

/**
 * Checks if there is any new type, which doesn't exist in old models
 *
 * @function
 * @param {object} [oldModels] - Old models
 * @param {object} newModels - New models
 * @returns {boolean} - True is new models has any type which doesn't exist in old models
 */
export const hasMissingModels = (oldModels, newModels) => {
    if (!oldModels && newModels && Object.keys(newModels).length) {
        return true;
    }
    if (oldModels && newModels && Object.keys(newModels).length) {
        return Object.keys(newModels).some((type) => !oldModels[type]);
    }
    return false;
};
