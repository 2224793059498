import {SET_DATA} from '../../actions/actionsTypes';

export default function (state = {}, action) {
    switch (action.type) {
        case SET_DATA: {
            const widgetId = Object.keys(action.payload)[0];
            return {
                ...state,
                [widgetId]: action.payload[widgetId]
            };
        }
        default:
            return state;
    }
}
