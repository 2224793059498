import {REMOVE_SELECTED_OBJECTS_CHANGED, SELECTED_OBJECTS_CHANGED} from '../../actions/actionsTypes';

export const removeSelectedObjects = (selectedObjects, objectsToRemove = []) => {
    return (selectedObjects || []).filter(({identifier, type}) => {
        return !(objectsToRemove || []).some((objectToRemove) => {
            return objectToRemove.identifier === identifier && objectToRemove.type === type;
        });
    });
};

export default function (state = {}, {type, payload} = {}) {
    switch (type) {
        case SELECTED_OBJECTS_CHANGED: {
            const widgetId = Object.keys(payload)[0];
            return {
                ...state,
                [widgetId]: {
                    ...state[widgetId],
                    selectedObjects: [...payload[widgetId]]
                }
            };
        }
        case REMOVE_SELECTED_OBJECTS_CHANGED: {
            const widgetId = Object.keys(payload)[0];
            const currentSelectedObjects = state?.[widgetId]?.selectedObjects || [];
            return {
                ...state,
                [widgetId]: {
                    ...state[widgetId],
                    selectedObjects: removeSelectedObjects(currentSelectedObjects, payload?.[widgetId])
                }
            };
        }
        default:
            return state;
    }
}
