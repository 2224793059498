/**
 * Created by Gawron on 2015-07-03.
 */
import EventBus from '../EventBus';

window.SwingApp = {
    loadingFinished: false,

    /**
     * Sends event to underlying app (single app mode) or view's event bus (full view mode). Event is delivered
     * asynchronously.
     * <p>
     * Note: due to backward compatibility order of arguments is far from optimal.
     *
     * @memberOf SwingApp
     * @param {String} p_source source of event (identifier), interpeted as if it was component's EId
     * @param {String} p_eventType event type i.e. 'update'
     * @param {String|Object} event data, i.e. key of managed entity key. If it is JSON parsable text, then it will be
     * parsed (Swing cannot deliver complex types to Javascript, so it might wrap them as JSON String).
     * @param p_callback {Function} optional callback to be informed when event has been dispatched.
     * @param {String} p_destinations optional arguments that allows to set specific destinations. Message is delivered
     * into event but and all components will receive it. This parameter allows to set comma separated list of
     * components EId that should receive the event (other components will not be notified). Or, alternatively, list of
     * components that should not receive the event: simply prefix their EId with ~. Do not mix both inclusion and
     * exclusion, results are unpredictable.
     * @param {String|Object} p_object optional, indicates 'obj' of OssEvent ("object"). I.e. if data is managed entity
     * key of some mev, then the object might be the mev itself. Contrary to data, object is considered optional, though
     * some apps may use it if it is provided. Like with data argument, it is recommended to pass this value as JSON
     * parsable text(its the only way if this method is called from Swing)
     */
    dispatchEvent_async: function (p_source, p_eventType, p_data, p_callback, p_destinations, p_object) {
        window.setTimeout(function () {
            try {
                SwingApp.dispatchEvent(p_source, p_eventType, p_data, null, p_destinations, p_object);
            } catch (error) {
                console.error("Failed to deliver java script message [tried to deliver message asynchronously]",
                    error);
            } finally {
                if (p_callback) {
                    p_callback();
                }
            }
        }, 100);
    },

    /**
     * Sends event to underlying app (single app mode) or view's event bus (full view mode). Event is delivered
     * synchronously (immediately).
     * <p>
     * Note: due to backward compatibility order of arguments is far from optimal.
     *
     * @memberOf SwingApp
     * @param {String} p_source source of event (identifier), interpeted as if it was component's EId
     * @param {String} p_eventType event type i.e. 'update'
     * @param {String|Object} event data, i.e. key of managed entity key. If it is JSON parsable text, then it will be
     * parsed (Swing cannot deliver complex types to Javascript, so it might wrap them as JSON String).
     * @param p_callback {Function} optional callback to be informed when event has been dispatched. It has little use
     * here.
     * @param {String} p_destinations optional arguments that allows to set specific destinations. Message is delivered
     * into event but and all components will receive it. This parameter allows to set comma separated list of
     * components EId that should receive the event (other components will not be notified). Or, alternatively, list of
     * components that should not receive the event: simply prefix their EId with ~. Do not mix both inclusion and
     * exclusion, results are unpredictable.
     * @param {String|Object} p_object optional, indicates 'obj' of OssEvent ("object"). I.e. if data is managed entity
     * key of some mev, then the object might be the mev itself. Contrary to data, object is considered optional, though
     * some apps may use it if it is provided. Like with data argument, it is recommended to pass this value as JSON
     * parsable text(its the only way if this method is called from Swing)
     */
    dispatchEvent: function (p_source, p_eventType, p_data, p_callback, p_destinations, p_object) {
        var data;
        var object;
        var event;

        try {
            data = JSON.parse(p_data);
        } catch (error) {
            console.log("Not a JSON event, treating data as text: " + p_data);
            data = p_data;
        }

        if (p_object) {
            try {
                object = JSON.parse(p_object);
            } catch (error_2) {
                console.log("Not a JSON event, treating object as text: " + p_object);
                object = p_object;
            }
        }
        EventBus.post(
            {
                getEId: function () {
                    return '_SWING_';
                }
            },
            p_eventType,
            p_data
        );

        if (p_callback) {
            p_callback();
        }

        return true;
    },

    loadView: function () {
        //alert("loadView");
        //this.setFirebug(true);
        this.loadingFinished = true;
    },

    /**
     * Loads Firebug debuggin tool. It might be the only way to inspect web within Swing web browser.
     *
     * @memberOf SwingApp
     */
    loadFirebug: function (p_visible, p_force) {
        if (window.Firebug) {
            SwingApp.log("Firebug is already loaded.");
            if (!p_force) {
                return;
            }
        }
        var js = document.createElement('script');
        js.type = 'text/javascript';
        js.src = '../../war/js/external/firebug-lite/build/firebug-lite.js';
        if (p_visible) {
            var options = '{ startOpened: true, startInNewWindow: true }';
            var optionsNode = document.createTextNode(options);
            js.appendChild(optionsNode);
        }


        var head = document.getElementsByTagName('head')[0];
        head.appendChild(js);
    },

    /**
     * Allows to load and toggle visibility of Firebug debugging tool.
     *
     * @memberOf SwingApp
     * @param {Boolean} p_visible true to show firebug, false to hide it.
     */
    setFirebug: function (p_visible) {
        //var visible = p_visible === undefined || p_visible === null || p_visible;
        var visible = true;
        var oldConsoleLog = console.log;
        var oldConsoleDebug = console.debug;
        var oldConsoleWarn = console.warn;
        var oldConsoleError = console.error;


        if (visible && !window.Firebug) {
            SwingApp.loadFirebug(true);
        }

        if (visible && window.Firebug && window.Firebug.chrome) {
            SwingApp.loadFirebug(true, true);
            window.Firebug.chrome.close();
            window.Firebug.chrome.toggle();
        } else if (!visible && window.Firebug && window.Firebug.chrome) {
            window.Firebug.chrome.close();
        }

        if (p_visible) {
            var delegateLogs = function (p_oldLogger, p_loggerName) {
                var newLogger = console[p_loggerName];
                if (p_oldLogger !== newLogger && (typeof(newLogger) === 'function') && (typeof(p_oldLogger) === 'function')) {
                    console[p_loggerName] = function () {
                        p_oldLogger.apply(this, arguments);
                        newLogger.apply(this, arguments);
                    };
                }
            };
            window.setTimeout(function () {
                delegateLogs(oldConsoleLog, 'log');
                delegateLogs(oldConsoleDebug, 'debug');
                delegateLogs(oldConsoleWarn, 'warn');
                delegateLogs(oldConsoleError, 'error');
            }, 2000);
        }
    }
};
