import {CURRENT_TAB_CHANGED} from '../../../actions/actionsTypes';

const getDefaultChunks = () => {
    return {
        tables: {},
        tabs: {}
    };
};

export default function (state = getDefaultChunks(), {type, payload}) {
    switch (type) {
        case CURRENT_TAB_CHANGED: {
            const widgetId = Object.keys(payload)[0];
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [widgetId]: {
                        ...state.tabs[widgetId],
                        ...payload[widgetId]
                    }
                }
            };
        }
        default:
            return state;
    }
}
