import {url} from '@web-core/utils';

export const {
    getQueryParams,
    addQueryParams,
    isEncodedPattern,
    decodeQueryParam,
    decodeURLPatterns,
    encodeURL,
    getURLPath,
    getURLQueryParamsString,
    removeQueryParams,
    replaceQueryParams,
    setQueryParams
} = url;
