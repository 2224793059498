export default function (state = {}, action) {
    switch (action.type) {
        case 'GENERATE_URL_SERVICE_PATH': {
            return {
                ...state,
                generateURLServicePath: action.generateURLServicePath
            };
        }
        case 'UPDATE_VIEW_STATE': {
            return {
                ...state,
                viewState: action.viewState
            };
        }
        default: {
            return state;
        }
    }
}