import {object} from '@web-core/utils';

export const {
    revertResolvePatterns,
    findInObjectByPredicate,
    deepCloneObject,
    resolvePath,
    replacePath,
    extendedResolvePatterns,
    findPathsInObject,
    isObject,
    replaceInObjectByPredicate,
    resolvePatterns,
    ARRAY_PATTERN
} = object;
