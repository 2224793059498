class Reducers {
    static getInitialState = () => ({
        data: null,
        error: null,
        isLoading: false
    });

    static getNewState = (data, isLoading, error, widgetId) => {
        if (widgetId) {
            return {
                [widgetId]: {
                    data,
                    error,
                    isLoading,
                }
            };
        }

        return {
            data,
            error,
            isLoading
        };
    };

    static asyncReducersFactory = (type) => {
        return (state = Reducers.getInitialState, action) => {
            switch (action.type) {
                case `LOAD_${type}_REQUEST`:
                    return Object.assign(
                        {},
                        state,
                        Reducers.getNewState(null, true, null, action.payload.widgetId));
                case `LOAD_${type}_SUCCESS`:
                    return Object.assign(
                        {},
                        state,
                        Reducers.getNewState(action.payload.data, false, null, action.payload.widgetId));
                case `LOAD_${type}_FAILURE`:
                    return Object.assign(
                        {},
                        state,
                        Reducers.getNewState(null, false, action.payload.error, action.payload.widgetId));
                default:
                    return state;
            }
        };
    }
}

export default Reducers;
