import {setKeycloakInstance} from '@web-core/store';
import {KeycloakProvider, Multitenacy} from '@web-core/utils';
import axios from 'axios';
import Logger from '../Logger/Logger';

const KeycloakAPI = global.Keycloak;

// eslint-disable-next-line import/no-mutable-exports
export let keycloakInstance = null;

export const initKeycloakAPI = async (): Promise<any> => {
    try {
        const {data: multitenancyConfig} = await axios('multitenancy/config.json');

        const keycloak = new KeycloakProvider(KeycloakAPI, multitenancyConfig as Multitenacy);
        keycloak.init();

        keycloakInstance = keycloak.getClient();
        setKeycloakInstance(keycloakInstance);
        return keycloakInstance;
    } catch (e) {
        Logger.of('keycloakInstance').error(e);
        return null;
    }
};
