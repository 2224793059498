import {
    CONTEXT_BUTTONS_REQUESTED,
    CONTEXT_BUTTONS_DOWNLOADED,
    CONTEXT_BUTTONS_DOWNLOAD_FAIL
} from '../../actions/actionsTypes';

export default function (state = {}, action) {
    let widgetId;
    if (action && action.payload) {
        widgetId = action.payload.widgetId;
    }

    switch (action.type) {
        case CONTEXT_BUTTONS_REQUESTED: {
            return {
                ...state,
                [widgetId]: {
                    ...state[widgetId],
                    isLoading: true
                }
            };
        }
        case CONTEXT_BUTTONS_DOWNLOADED: {
            return {
                ...state,
                [widgetId]: {
                    ...state[widgetId],
                    allActions: action.payload.actions || [],
                    isLoading: false
                }
            };
        }
        case CONTEXT_BUTTONS_DOWNLOAD_FAIL: {
            return {
                ...state,
                [widgetId]: {
                    ...state[widgetId],
                    isLoading: false,
                    error: action.payload.error
                }
            };
        }
        default:
            return state;
    }
}
