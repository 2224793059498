import {TypeAttributes, TypeDTO} from '@web-core/graphql';
import {
    PendingTypesHandler,
    TypeAttributesInternalConfig,
    UseModelData,
    UseModelTypesAttributesConfig,
    UseModelTypesConfig
} from './types/useModelTypes';

export const DEFAULT_KEY = 'default';

const DATA_INTERNAL_CONFIG_FIELD = {
    DATA: 'data',
    FAIL: 'fail'
};

export const uniqueArray = <T = any>(array: T[]): T[] => [...new Set(array)];

export const getKeyForHandler = (type: string, cacheKey?: string): string => {
    return [type, cacheKey].filter(Boolean).join('-');
};

const dataTypesMapper = (types: string[], model: UseModelTypesConfig): TypeDTO[] => {
    return types.map((type: string): TypeDTO => model?.[type]?.data).filter(Boolean);
};

const dataTypesAttributesMapper = (
    types: string[],
    model: UseModelTypesAttributesConfig
): UseModelTypesAttributesConfig => {
    return types.reduce(
        (result, type: string): UseModelTypesAttributesConfig => ({
            ...result,
            [model?.[type]?.type || type]: model?.[type]?.data
        }),
        {}
    );
};

const filterTypesBy = (
    model: UseModelTypesConfig | UseModelTypesAttributesConfig,
    types: string[],
    propertyName: string,
    cacheKey?: string
): string[] => {
    return types.filter((type: string): boolean => {
        const key = getKeyForHandler(type, cacheKey);
        return !!model?.[key]?.[propertyName];
    });
};

function handlePendingPromises(
    awaitingFetching: PendingTypesHandler<TypeDTO[]>[],
    model: UseModelTypesConfig,
    dataMapper: (types: string[], model: UseModelTypesConfig) => TypeDTO[]
): PendingTypesHandler<TypeDTO[]>[];
function handlePendingPromises(
    awaitingFetching: PendingTypesHandler<TypeAttributes>[],
    model: UseModelTypesAttributesConfig,
    dataMapper: (types: string[], model: UseModelTypesAttributesConfig) => UseModelTypesAttributesConfig
): PendingTypesHandler<TypeAttributes>[];
function handlePendingPromises(
    awaitingFetching: PendingTypesHandler[],
    model: UseModelTypesConfig | UseModelTypesAttributesConfig,
    dataMapper: any
): PendingTypesHandler[] {
    return awaitingFetching.filter(({types, cacheKey, resolve, reject}): boolean => {
        const failed = filterTypesBy(model, types, DATA_INTERNAL_CONFIG_FIELD.FAIL, cacheKey);
        if (failed.length) {
            reject();
            return true;
        }
        const done = filterTypesBy(model, types, DATA_INTERNAL_CONFIG_FIELD.DATA, cacheKey);
        if (done.length === types.length) {
            const modelKeys = types.map((type: string): string => getKeyForHandler(type, cacheKey));
            const mappedData = dataMapper(modelKeys, model);
            resolve(mappedData);
            return true;
        }
        return false;
    });
}

export const handlePendingTypes = (
    types: PendingTypesHandler<TypeDTO[]>[],
    model: UseModelTypesConfig
): PendingTypesHandler<TypeDTO[]>[] => {
    return handlePendingPromises(types, model, dataTypesMapper);
};

export const handlePendingTypesAttributes = (
    attributes: PendingTypesHandler<TypeAttributes>[],
    model: UseModelTypesAttributesConfig
): PendingTypesHandler<TypeAttributes>[] => {
    return handlePendingPromises(attributes, model, dataTypesAttributesMapper);
};

export const mergeModelData = (types: UseModelTypesConfig, attributes: UseModelTypesAttributesConfig): UseModelData => {
    const keysA = Object.keys(types || {});
    const attributesValues = Object.values(attributes || {});
    const mappedAttributes = attributesValues.reduce(
        (
            result: UseModelTypesAttributesConfig,
            attribute: TypeAttributesInternalConfig
        ): UseModelTypesAttributesConfig => {
            return {
                ...result,
                [attribute.type]: attribute
            };
        },
        {}
    );
    const keysB = Object.keys(mappedAttributes || {});
    const allTypes = uniqueArray<string>([...keysA, ...keysB]);
    return allTypes.reduce(
        (model: UseModelData, type: string): UseModelData => ({
            ...model,
            [type]: {
                type: types[type]?.data,
                attributes: mappedAttributes[type]?.data
            }
        }),
        {}
    );
};

export const customTypesEqual = <T extends UseModelTypesConfig | UseModelTypesAttributesConfig>(
    objA: T,
    objB: T
): boolean => {
    const keyA = Object.keys(objA || {});
    const keyB = Object.keys(objB || {});
    return (
        keyA.length === keyB.length &&
        keyA.every((type: string, index: number): boolean => {
            return type === keyB[index] && objA[type].data === objB[type].data && objA[type].fail === objB[type].fail;
        })
    );
};
