import PropTypes from 'prop-types';
import {HashRouter as Router} from 'react-router-dom';

const Root = ({routes}) => {
    return (
        <Router>
            {routes()}
        </Router>
    );
};

Root.propTypes = {
    routes: PropTypes.func.isRequired
};

export default Root;
