import {REORDER_COLUMNS, RESIZE_COLUMNS, ERASE_PREFERENCES} from '../../../actions/actionsTypes';

const updateChunk = (state, payload) => {
    const tablesChunk = {...state.tables};

    const widgetIDs = Object.keys(payload);
    widgetIDs.forEach((widgetID) => {
        const newValue = payload[widgetID];
        const oldValue = tablesChunk[widgetID] || {};
        tablesChunk[widgetID] = {
            ...oldValue,
            ...newValue
        };
    });

    return {
        ...state,
        tables: tablesChunk
    };
};

const eraseChunk = (state, widgetId) => {
    const tablesChunk = {...state.tables};
    tablesChunk[widgetId] = {};

    return {
        ...state,
        tables: tablesChunk
    };
};

const getDefaultChunks = () => {
    return {
        tables: {},
        tabs: {}
    };
};

export default function (state = getDefaultChunks(), action) {
    switch (action.type) {
        case REORDER_COLUMNS:
            return updateChunk(state, action.payload);
        case RESIZE_COLUMNS:
            return updateChunk(state, action.payload);
        case ERASE_PREFERENCES:
            return eraseChunk(state, action.widgetId);
        default:
            return state;
    }
}
