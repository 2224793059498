import {
    ALPHA_MODE_CHANGE,
    OSS_CONSOLE_CONFIGURATOR,
    SOUND_NOTIFICATIONS_CHANGE,
    EXTEND_LOGGING_CHANGE,
    LOGGING_LEVEL_CHANGE
} from './actionsTypes';
import {add, get} from '../js/oss/localStorageUtils';
import {getUserId, getUserIdFromToken} from '../js/oss/utils';
import {ROLES} from '../js/FrameworkConstants';

const ACTIONS_MAPPER = {
    mode: ALPHA_MODE_CHANGE,
    soundNotifications: SOUND_NOTIFICATIONS_CHANGE,
    isLoggingExtended: EXTEND_LOGGING_CHANGE,
    loggingLevel: LOGGING_LEVEL_CHANGE
};

const SETTINGS_ROLE = {
    mode: ROLES.DEVELOPER_MODE,
    soundNotifications: ROLES.SOUND_NOTIFICATION,
    isLoggingExtended: ROLES.DEVELOPER_MODE,
    loggingLevel: ROLES.DEVELOPER_MODE
};

export const MODE = {
    ALPHA: 'ALPHA',
    DEFAULT: 'DEFAULT'
};

export const getActionType = (key) => ACTIONS_MAPPER[key];

export const getUserRole = (key) => SETTINGS_ROLE[key];

export function changeGlobalMode(mode) {
    return (dispatch) => {
        add(getUserId(), {mode});
        dispatch({
            type: ALPHA_MODE_CHANGE,
            payload: {
                mode
            }
        });
    };
}

export function changeSoundNotifications(soundNotifications) {
    return (dispatch) => {
        add(getUserId(), {soundNotifications});
        dispatch({
            type: SOUND_NOTIFICATIONS_CHANGE,
            payload: {
                soundNotifications
            }
        });
    };
}

export function changeExtendLogging(isLoggingExtended) {
    return (dispatch) => {
        add(getUserId(), {isLoggingExtended});
        dispatch({
            type: EXTEND_LOGGING_CHANGE,
            payload: {
                isLoggingExtended
            }
        });
    };
}

export function setConfiguratorRole() {
    return (dispatch) => {
        dispatch({
            type: OSS_CONSOLE_CONFIGURATOR
        });
    };
}

export const isAlphaModeInStorage = () => {
    const localStorage = get(getUserIdFromToken());
    return localStorage?.mode === MODE.ALPHA;
};

export const isLoggingExtendedInStorage = () => {
    const localStorage = get(getUserIdFromToken());
    return !!localStorage?.isLoggingExtended;
};

export function setLoggingLevel(loggingLevel) {
    return (dispatch) => {
        add(getUserIdFromToken(), {loggingLevel});
        dispatch({
            type: LOGGING_LEVEL_CHANGE,
            payload: {
                loggingLevel
            }
        });
    };
}
