import '@babel/polyfill';
import ReactDOM from 'react-dom';

import '../libs/libs';
import './oss/SwingApp';
import '../../fontsLoader';
import '../libs/fontAwesome5';
import {initKeycloakAPI} from './keycloakInstance';

import Root from '../OssApp/Root';
import Logger from '../Logger/Logger';

// Dynamic import
// It is very important feature.
// Thanks that whole application is imports and renders after keycloak has been initiated.
const initOssApp = async () => {
    const {default: routes} = await (() => import('../OssApp/routes/MainRoutes'))();
    ReactDOM.render(<Root routes={routes} />, document.getElementById('ossApp'));
};

(async () => {
    const keycloak = await initKeycloakAPI();

    if (keycloak) {
        keycloak
            .init({onLoad: 'login-required', pkceMethod: 'S256'})
            .then(initOssApp)
            .catch((error) => {
                Logger.of('app').error(error);
            });
    } else {
        initOssApp().catch((error) => {
            Logger.of('app').error(error);
        });
    }
})();
