import {isEqual} from 'underscore';
import {DECORATION_CHANGED, DECORATION_REMOVED} from '../actions/actionsTypes';

const findDecoratorsIdxById = (decorators, decorationId) => {
    const {type, name} = decorationId;
    return decorators.findIndex(decorator => decorator.type === type && decorator.name === name);
};

const shouldAddAttributesToSameDecoratorType = (decorator, newDecorator) => {
    return decorator.type === newDecorator.type && decorator.name === newDecorator.name;
};

const keyForDecorationId = ({type, name}) => `${type}:${name}`;

const uniqueArray = array => [...new Set(array)];

const changeDecorationById = (decorations = [], decorationId, styles, source) => {
    const {decorators = [], sources = {}} = decorations;
    const newDecorations = [...decorators];
    const idx = findDecoratorsIdxById(decorators, decorationId);

    const newDecoratorItem = {
        ...decorationId,
        ...styles
    };
    const decorationKey = keyForDecorationId(decorationId);

    if (idx >= 0) {
        // Update, for now use override strategy
        if (isEqual(decorators[idx], newDecoratorItem) && (sources?.[decorationKey] || []).includes(source)) {
            return decorations;
        }
        if (shouldAddAttributesToSameDecoratorType(decorators[idx], newDecoratorItem)) {
            newDecoratorItem.attributes = uniqueArray([
                ...(decorators[idx].attributes || []),
                ...(newDecoratorItem.attributes || [])
            ]);
        }
        newDecorations.splice(idx, 1, newDecoratorItem);
    } else {
        newDecorations.push(newDecoratorItem);
    }

    return {
        decorators: newDecorations,
        sources: {
            ...(sources || {}),
            [decorationKey]: uniqueArray([...(sources?.[decorationKey] || []), source])
        }
    };
};

const changeDecoration = (state, objectId, decorationId, styles, source) => {
    const {type, identifier} = objectId;
    const decoration = ((state[type] || {})[identifier] || []);
    const changedDecoration = changeDecorationById(decoration, decorationId, styles, source);
    return {
        ...state,
        [type]: {
            ...(state[type] || {}),
            [identifier]: changedDecoration
        }
    };
};

const removeDecoratorsByIndex = (decorations, decorationId, idx, decorationKey) => {
    const {decorators = [], sources = {}} = decorations;
    const newDecorators = [...decorators];
    const {attributes = []} = newDecorators[idx];
    const newAttributes = attributes.filter(attribute => !decorationId.attributes.includes(attribute));
    if (newAttributes.length > 0) {
        newDecorators[idx] = {
            ...newDecorators[idx],
            attributes: newAttributes
        };
    } else {
        newDecorators.splice(idx, 1);
    }

    const newSources = {...sources};
    delete newSources[decorationKey];

    return {
        decorators: newDecorators,
        sources: newSources
    };
};

const removeDecorationById = (decorations, decorationId, source) => {
    const {decorators = [], sources = {}} = decorations || {};
    const idx = findDecoratorsIdxById(decorators, decorationId);
    if (idx >= 0) {
        const decorationKey = keyForDecorationId(decorationId);
        const sourcesForKey = (sources?.[decorationKey] || []);
        if (!source || (source && sourcesForKey.includes(source) && sourcesForKey.length <= 1)) {
            return removeDecoratorsByIndex(decorations, decorationId, idx, decorationKey);
        }

        const newSources = {
            ...(sources || {}),
            [decorationKey]: sourcesForKey.filter(sourceId => sourceId !== source)
        };
        return {
            decorators,
            sources: newSources
        };
    }
    return decorations;
};

const removeDecoration = (state, objectId, decorationId, source) => {
    const {type, identifier} = objectId;
    if (state && state[type] && state[type][identifier]) {
        const removedDecorations = removeDecorationById(state[type][identifier], decorationId, source);
        const result = {
            ...state,
            [type]: {
                ...state[type],
                [identifier]: removedDecorations
            }
        };
        //clean empty decorations for type:identifier
        if (result[type][identifier]?.decorators?.length === 0) {
            delete result[type][identifier];
        }
        //clean empty decorations for type
        if (Object.keys(result[type]).length === 0) {
            delete result[type];
        }

        return result;
    }
    return state;
};

const invokeChangeDecorations = (state, payload) => {
    if (Array.isArray(payload)) {
        return payload.reduce((result, object) => {
            const {objectId, decorationId, styles, source} = object;
            return changeDecoration(result, objectId, decorationId, styles, source);
        }, state);
    }
    const {objectId, decorationId, styles, source} = payload;
    return changeDecoration(state, objectId, decorationId, styles, source);
};

const invokeRemoveDecorations = (state, payload) => {
    if (Array.isArray(payload)) {
        return payload.reduce((result, object) => {
            const {objectId, decorationId, source} = object;
            return removeDecoration(result, objectId, decorationId, source);
        }, state);
    }
    const {objectId, decorationId, source} = payload;
    return removeDecoration(state, objectId, decorationId, source);
};

export default function (state = {}, {type, payload}) {
    switch (type) {
        case DECORATION_CHANGED: {
            return invokeChangeDecorations(state, payload);
        }
        case DECORATION_REMOVED: {
            return invokeRemoveDecorations(state, payload);
        }
        default:
            return state;
    }
}
