import {LANGUAGE_CHANGE} from '../actions/actionsTypes';

export default function (state = {}, {type, payload}) {
    switch (type) {
        case LANGUAGE_CHANGE: {
            return {...state, ...payload};
        }
        default:
            return state;
    }
}
