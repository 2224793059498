import {SET_ACTIVE_DASHBOARD, SET_DASHBOARD_BUTTONS_LIST, SET_FAVOURITE_LIST} from '../actions/actionsTypes';

const defaultChunk = {
    activeDashboardId: null,
    buttonsList: [],
    favoriteList: []
};

const updateFavoriteList = (favoriteList, viewId) => {
    return favoriteList.map((tab) => {
        return {
            ...tab,
            isActive: tab?.viewId === viewId
        };
    });
};

export default (state = defaultChunk, {type, payload} = {}) => {
    const {viewId, buttonsList, favoriteList} = payload || {};
    switch (type) {
        case SET_ACTIVE_DASHBOARD: {
            if (viewId) {
                const newFavoriteList = updateFavoriteList(state.favoriteList, viewId);
                return {
                    ...state,
                    activeDashboardId: viewId,
                    favoriteList: newFavoriteList
                };
            }
            return state;
        }
        case SET_FAVOURITE_LIST: {
            return {
                ...state,
                favoriteList: updateFavoriteList(favoriteList || [], state?.activeDashboardId)
            };
        }
        case SET_DASHBOARD_BUTTONS_LIST: {
            return {
                ...state,
                buttonsList: buttonsList || []
            };
        }
        default:
            return state;
    }
};
