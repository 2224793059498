let QCQueryParams = {};
export const setQCQueryParams = (params) => {
    QCQueryParams = params;
};

export const getQCQueryParams = () => {
    return QCQueryParams;
};

export const hasQCQueryParams = (url) => {
    return (/perspective/ig).test(url);
};
