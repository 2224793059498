import {TOUCHED_OBJECTS} from '../../actions/actionsTypes';

export default function (state = {}, action) {
    switch (action.type) {
        case TOUCHED_OBJECTS: {
            return {
                ...action.payload
            };
        }
        default:
            return state;
    }
}
