import {
    GET_FOLDERS_NAVIGATION, SET_UPDATED_FAVOURITES,
    TOGGLE_FAVOURITE
} from '../../actions/actionsTypes';

export default function (state, actions) {
    switch (actions.type) {
        case GET_FOLDERS_NAVIGATION: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...actions.payload.rootNavigation,
                },
                favourites: {
                    ...state.favourites,
                    ...actions.payload.favourites
                }
            };
        }
        case TOGGLE_FAVOURITE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    subfolders: actions.payload.newFolders,
                },
                favourites: {
                    ...state.favourites,
                    ...actions.payload.newFavouriteFolders
                }
            };
        }
        case SET_UPDATED_FAVOURITES: {
            return {
                ...state,
                favourites: {
                    ...state.favourites,
                    ...actions.payload.newFavourites
                }
            };
        }
        default:
            return state;
    }
}
