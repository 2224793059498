const MODAL_PREFIX = 'MODAL_';

const prepareAppId = function (appHandler) {
    if (appHandler.props) {
        return (appHandler.props.isModal ? MODAL_PREFIX : '') + appHandler.props.id;
    }
    return appHandler.getEId();
};

export default prepareAppId;
