import {getQCQueryParams, setQCQueryParams} from './queryContextParams';
import {
    addQueryParams,
    removeQueryParams,
    getQueryParams,
    setQueryParams,
    replaceQueryParams
} from '../helpers/urlHelpers';

export const getListOfQueryParams = getQueryParams;
export const deleteQueryParams = removeQueryParams;

export const deleteListOfQueryParams = removeQueryParams;
export const addListOfQueryParams = addQueryParams;

export const setListOfQueryParams = setQueryParams;

export {addQueryParams, getQueryParams, setQueryParams, replaceQueryParams};

// --- Query Context methods! ---
export {setQCQueryParams, getQCQueryParams};
