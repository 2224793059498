import {setAlphaMode, setLoggingExtended} from '@web-core/store';
import {
    ALPHA_MODE_CHANGE,
    OSS_CONSOLE_CONFIGURATOR,
    SOUND_NOTIFICATIONS_CHANGE,
    LOGGING_LEVEL_CHANGE,
    EXTEND_LOGGING_CHANGE
} from '../actions/actionsTypes';
import {MODE} from '../actions/globalOptionsActions';

const defaultState = {
    soundNotifications: true,
    isLoggingExtended: false,
    mode: MODE.DEFAULT,
    loggingLevel: 1
};

export default function (state = defaultState, {type, payload} = {}) {
    switch (type) {
        case ALPHA_MODE_CHANGE: {
            setAlphaMode(payload.mode === MODE.ALPHA);
            return {
                ...state,
                mode: payload.mode
            };
        }
        case SOUND_NOTIFICATIONS_CHANGE: {
            return {
                ...state,
                soundNotifications: payload.soundNotifications
            };
        }
        case OSS_CONSOLE_CONFIGURATOR: {
            return {
                ...state,
                ossConsoleConfigurator: true
            };
        }
        case LOGGING_LEVEL_CHANGE: {
            return {
                ...state,
                loggingLevel: payload.loggingLevel
            };
        }
        case EXTEND_LOGGING_CHANGE: {
            setLoggingExtended(payload.isLoggingExtended);
            return {
                ...state,
                isLoggingExtended: payload.isLoggingExtended
            };
        }
        default:
            return state;
    }
}
